import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import bwipjs from 'bwip-js';
import ScanActionRequiredVideoPopupModal from '../scan-action-required-video-modal';

const ScanActionRequiredPopupModal = ({ open, handleClose, qrText, message }) => {
  const canvasRef = useRef(null);
  const [openAppDownload, setOpenAppDownload] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        if (open && canvasRef.current && qrText.length > 0) {
          bwipjs.toCanvas(canvasRef.current, {
            bcid: 'qrcode', // Barcode type
            text: qrText,  // Text to encode
            scale: 4,      // 3x scaling factor
            includetext: false, // No human-readable text
        });
        }
      } catch (e) {
          console.error(e);
      }
    }, 100); 
    
  }, [open, qrText]);

  let title = 'Confirm Inscan from Branch App'
  if (qrText && qrText.includes('drs_closure')) {
    title = 'Confirm Inward from Branch App'
  }

  const handleCancel = e => {
    setOpenAppDownload(false)
  };



  return (
    <Modal className="remove-padding" title={
        <div style={{ display: 'flex', justifyContent: 'center' }}> 
          <div>{title}</div> 
        </div>
      }
      onCancel={handleClose} visible={open}
      width="40vw" footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}> 
              <Button type="link" onClick={_ => setOpenAppDownload(true)} style={{ padding : 0 }}>
                How do i use this?
              </Button>
          </div>
      } centered={true}
      destroyOnClose={true} >
        <div>
        
        <div style={{ display: 'flex', justifyContent: 'center', margin: 30}}> 
            <canvas ref={canvasRef} id="qrCanvas"></canvas>
        </div>
       
        </div>

        <ScanActionRequiredVideoPopupModal handleClose={handleCancel}
                        open={openAppDownload}
                    />
      
    </Modal>
  )
}

export default ScanActionRequiredPopupModal;