import React, { PureComponent } from 'react';
import * as actions from './actions';
import * as actionspartner from '../../shared/header/action';
import './form.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Modal, Form, PageHeader, Divider, notification, Select, Icon, Radio, Spin, Checkbox } from 'antd';
import { canAllow } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import { Constants } from '../../../common/utils/constants';
import RouteService from '../../../services/settings/route.service';
import moment from 'moment';
import SuperSelect from "../../ui/SuperSelect/SuperSelect";
import debounce from "lodash/debounce";

const Option = Select.Option;
const { confirm } = Modal;

let id = 0;

class RouteForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tripType: [{ label: 'One Way', value: 'oneway' }, { label: 'Round Trip', value: 'roundtrip' }],
      lastRun: true,
      indexmaintain: null,
      routeMappingType: 'location',
      dublicateLocation: [],
      zones: [],
      location: {},
      zonelevelData: [],
      editDestinations: {},
      partnerData: [],
      editDestinationspartners: {},
      transitTime: {},
      getAlllocation: true,
      setfirstLocation: true,
      eligibleForTrip: false,
      routemappingLocaton: true,
      pathSelected: false,
      pathValue: "NA",
      tripTypeadd: true,
      startlocationsData: [],
      locationLoader: false,
      destinationLocationLoader: false,
      zoneLoading: false,
      isRouteLoadingbox: false,
      isRouteLoading: false,
      isRouteFullLoad: false,
      firstLocation: [],
      selectedRoute: {
        loading: false,
        data: []
      },
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          isActive: true,
        }
      },
      routePermission: {
        read: canAllow(permissionTags.settings.route.read),
        update: canAllow(permissionTags.settings.route.update),
        create: canAllow(permissionTags.settings.route.create),
        delete: canAllow(permissionTags.settings.route.delete),
        view: canAllow(permissionTags.settings.route.view),
        zone: canAllow(permissionTags.settings.zone.read)
      },
      routeId: props.match.params.id,
      isEdit: !(props.match.params.id > 0),
    }
    this.onLocationSearch = debounce(this.onLocationSearch, 800);
    if (!this.state.routePermission.read) {
      window.location.replace('/appv2/settings/dashboard/route');
    }
  }

  componentDidMount() {
    // this.getStartLocations(Constants.PARTNER_ID);
    // this.props.getPartnerDetails(Constants.PARTNER_ID);
    // this.getZones();
    // this.handleRoutemappingType({ target: { value: "location" } });
    if (this.state?.routeId) {
      this.props.getRoute(this.state.routeId);
      // this.setState({ isRouteLoadingbox: true, isRouteLoading: true, isRouteFullLoad: true });
    } else {
      this.setState({ isRouteLoading: false });
    }
  }

  getZones = () => {
    this.setState({ zoneLoading: true })
    this.props.getZones().then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        this.setState({ zoneLoading: false })
        this.setState({ zonelevelData: res?.data?.response?.zones });
      } else {
        notification['error']({
          message: 'ERROR',
          description: res?.data?.status?.message,
          placement: 'bottomRight',
        });
      }
    });
  }

  getStartLocations = (param) => {
    this.setState({ locationLoader: true })
    let payload = {
      filters: {
        entityTypes: ['PARTNER'],
        roles: [],
        status: true
      }
    };

    if (Constants.PARTNER_ID !== param) {
      payload.filters.entityIds = [param];
      payload.filters.isConsiderCreatorId = false;
    }
    this.props.getStartLocationData(param, payload).then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        this.setState({ locationLoader: false })
        let locationbox = { ...this.state.location };
        if (this.state.setfirstLocation === true) {
          locationbox['index-0'] = res?.data?.response?.locations;
          this.setState({
            firstLocation: res?.data?.response?.locations,
          }, () => {
            this.setState({ setfirstLocation: false, dublicateLocation: [{ index: 'index-0', value: Constants.PARTNER_ID }], startlocations: res?.data?.response?.locations, isRouteLoading: false, location: locationbox, isRouteLoadingbox: false, isRouteFullLoad: false })
          })
        } else {
          this.setState({ startlocations: res?.data?.response?.locations, location: locationbox, isRouteLoading: false, isRouteLoadingbox: false, isRouteFullLoad: false })
        }
      } else {
        notification['error']({
          message: 'ERROR',
          description: res?.data?.status?.message,
          placement: 'bottomRight',
        });
      }
    });
  }

  componentDidUpdate(prevProps, preState) {

    if (this.state.locations !== preState.locations) {
      this.setState({ location: this.props.locations });
    }

    if (this.state.startlocations !== preState.startlocations) {
      this.setLocationlevelData(this.state.startlocations);
    }

    if (this.state.isRouteLoadingbox !== preState.isRouteLoadingbox) {
      this.setState({ isRouteLoadingbox: false });
    }

    if (this.state.zonelevelData !== preState.zonelevelData || preState.disableValue !== this.state.disableValue) {
      this.setZonalData(this.state.zonelevelData);
    }

    if (this.props.partners !== prevProps.partners) {
      this.setPartnerslevelData(this.props.partners);
    }

    if (this.state.lastRun === true && this.props.selectedRoute !== prevProps.selectedRoute) {
      this.setState({
        selectedRoute: this.props.selectedRoute,
      }, () => {
        this.loadEditableFormData(this.state.selectedRoute);
        if (this.state.selectedRoute.routeMappingType.toLowerCase() === "location") {
          // this.getStartLocations(Constants.PARTNER_ID);
        }
      })
    }
  }

  setLocationEndData = (data) => {
    this.setState({ destinationLocationLoader: true });
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push((<Option disabled={this.state?.disableValue?.includes(x.id) ? true : false} value={x.id} key={x.id}>{x.name}</Option>));
      });
      this.setState({ destinationLocationLoader: false });
      return endLocationsData;

    } else {
      return;
    }
  }

  setPartnerslevelData = (data) => {
    if (data.length > 0) {
      let partnerData = [];
      data.map(x => {
        if (x.linkPartnerBO.name) {
          partnerData.push(<Option disabled={this.state?.disableValue?.includes(x.linkPartnerBO.id) ? true : false} value={x.linkPartnerBO.id} key={x.id}>{x.linkPartnerBO.name}</Option>);
        }
      })
      this.setState({ partnerData: partnerData })
    }
  }

  setLocationlevelData = (data) => {

    if (data.length > 0) {
      let startlocationsData = [];
      data.map(x => {
        startlocationsData.push(<Option disabled={this.state?.disableValue?.includes(x.id) ? true : false} value={x.id} key={x.id}>{x.name}</Option>);
      })
      this.setState({ startlocationsData: startlocationsData })
    }
  }

  setZonalData = (data) => {
    if (data.length > 0) {
      let zoneData = [];
      data.map(x => {
        zoneData.push(<Option disabled={this.state?.disableValue?.includes(x.id) ? true : false} value={x.id} key={x.id}>{x.name}</Option>);
      })
      this.setState({ zones: zoneData })
    }
  }

  onLocationRouteSelected = (mapper) => {

    let setlocation;
    let destLoc = {};
    if (mapper.length > 1) {
      setlocation = mapper.splice(0, 1);
    }
    if (setlocation) {
      setlocation[0].location.entityType === "CUSTOMER" ? this.onPartnerSourceChange(Constants.PARTNER_ID, true) : this.onPartnerSourceChange(setlocation[0].location.entityId, true);
    }
    let setKeys = {};
    let editDestinations = {};
    let editDestinationspartners = {};
    let transitTime = {};
    let combinedApis = [];
    let setApisData = [];
    mapper.forEach((x, index) => {
      id = ++id;
      setKeys['index-' + index] = index;
      x.location.entityType === "CUSTOMER" ? setApisData.push({ index: 'index-' + index, value: Constants.PARTNER_ID }) : setApisData.push({ index: 'index-' + index, value: x.location.entityId });
      x.location.entityType === "CUSTOMER" ? combinedApis.push(Constants.PARTNER_ID) : combinedApis.push(x.location.entityId);
      editDestinations['index-' + index] = x.location.id;
      x.location.entityType === "CUSTOMER" ? editDestinationspartners['index-' + index] = Constants.PARTNER_ID : editDestinationspartners['index-' + index] = x.location.entityId;
      transitTime['index-' + index] = x.transitTime;
      destLoc['index-' + index] = [x.location]
    })
    // this.onPartnerChange2(setApisData);
    this.props.form.setFieldsValue({ keys: setKeys });
    this.setState({
      lastdestination: editDestinations, transitTime: transitTime,
      destinationPartner: editDestinationspartners, sourceDestination: setlocation[0].location.id,
      location: destLoc
    });
  }

  onPartnerChange2 = (data) => {
    if (data.length > 0) {
      // this.getLocations2(data);
    }
    id = --id;
    this.setState({ indexmaintain: 'index-' + id })
  }

  getLocations2 = (data) => {
    let locationbox = { ...this.state?.location };
    const promises = [];
    const uniqueLocation = [...new Set(data.map(item => item.value))];

    uniqueLocation.forEach((partnerId) => {
      promises.push(this.getLocationsNumbers(partnerId));
    });
    Promise.all(promises)
      .then(values => {
        let dataObject = {};
        let dataArray = [];
        values.map(x => {
          dataObject[x.id] = x.values;
          dataArray.push(x.id);
        })
        let lastArray = [];
        data.map(x => {
          lastArray.push(dataObject[x.value]);
        })

        lastArray.map((x, key) => {
          let ux = "index-" + key;
          locationbox[ux] = x;
        })

        let setArray = [];
        let duplicatesetArray = [];
        data.map(x => {
          if (!setArray.includes(x.value)) {
            duplicatesetArray.push(x);
            setArray.push(x.value);
          }
        })
        this.setState({ location: locationbox, dublicateLocation: duplicatesetArray, isRouteFullLoad: false, isRouteLoadingbox: false, isRouteLoading: false });
      })
      .catch(err => {
        notification['error']({
          message: 'ERROR',
          description: 'ERROR',
          placement: 'bottomRight',
        });
      });
  }

  getLocationsNumbers = (param) => {
    let payload = {
      filters: {
        entityTypes: Constants.PARTNER_ID === param ? ['PARTNER', 'CUSTOMER'] : ['PARTNER'],
        roles: [],
        status: true,
      }
    };
    if (Constants.PARTNER_ID !== param) {
      payload.filters.entityIds = [param];
      payload.filters.isConsiderCreatorId = false;
    }
    return RouteService.getLocation(param, payload).then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        let data = { id: param, values: res?.data?.response?.locations }
        return data;
      }
    });
  }

  getLocations = (param, id) => {
    let locationbox = { ...this.state?.location };
    let payload = {
      filters: {
        entityTypes: Constants.PARTNER_ID === param ? ['PARTNER', 'CUSTOMER'] : ['PARTNER'],
        roles: [],
        status: true,
      }
    };
    if (Constants.PARTNER_ID !== param) {
      payload.filters.entityIds = [param];
      payload.filters.isConsiderCreatorId = false;
    }

    // RouteService.getLocation(param, payload).then(res => {
    //   if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
    //     locationbox[id] = res?.data?.response?.locations;
    //     this.setState({ location: locationbox });
    //   } else {
    //     notification['error']({
    //       message: 'ERROR',
    //       description: res?.data?.status?.message,
    //       placement: 'bottomRight',
    //     });
    //   }
    // });
  }

  onzoneRouteSelected = (mapper) => {
    let setlocation;
    if (mapper.length > 1) {
      setlocation = mapper.splice(0, 1);
    }
    let setKeys = {};
    let zonedestination = {};
    let zonetransitTime = {};
    let editDestinationspartners = {};
    mapper.forEach((x, index) => {
      id = ++id;
      setKeys['index-' + index] = index;
      zonedestination['index-' + index] = x.zone.id;
      zonetransitTime['index-' + index] = x.transitTime;
    })
    this.props.form.setFieldsValue({ keys: setKeys });
    editDestinationspartners['index-' + 0] = Constants.PARTNER_ID;
    this.setState({ zonedestination: zonedestination, isRouteLoadingbox: false, isRouteLoading: false, isRouteFullLoad: false, destinationPartner: editDestinationspartners, zonetransitTime: zonetransitTime, sourceZone: setlocation[0].zone.id });
  }

  loadEditableFormData(editData) {

    let setupdata = [...editData['routeMapping']];
    const isRoundTrip = this.checkIsRoundTrip(setupdata);
    let routeMapping = editData.routeMappingType.toLowerCase() === "location" ? true : false;
    if (isRoundTrip) {
      let setlocation = Math.round(setupdata.length / 2);
      setupdata = setupdata.slice(0, setlocation)
    }
    if (editData) {
      if (editData.routeMappingType.toLowerCase() === "location") {
        this.onLocationRouteSelected(setupdata);
      } else {
        this.onzoneRouteSelected(setupdata);
      }
      let tripData = [{ label: 'One Way', value: 'oneway' }, { label: 'Round Trip', value: 'roundtrip' }];;
      if (editData.routeType === 'PICKUP') {
        tripData = [{ label: 'Round Trip', value: 'roundtrip' }];
        this.setState({ tripTypeadd: false });
      }
      this.props.form.setFieldsValue({
        routeName: editData.name,
        path: null,
        tripType: isRoundTrip ? "roundtrip" : "oneway",
        eligibleForTrip: editData.eligibleForTrip,
      })
      let sourcePatnerData;
      if (editData?.routeMapping[0]?.location?.entityType) {
        sourcePatnerData = editData?.routeMapping[0]?.location.entityType === "CUSTOMER" ? editData?.routeMapping[0]?.location?.creatorId : editData?.routeMapping[0]?.location?.entityId;
      } else {
        sourcePatnerData = Constants.PARTNER_ID;
      }

      this.setLocationlevelData([editData?.sourceLocation])

      this.setState({ tripType: tripData, lastRun: false, routemappingLocaton: routeMapping, routeType: editData.routeType?.toLowerCase(), routeMappingType: editData.routeMappingType?.toLowerCase(), eligibleForTrip: editData.eligibleForTrip, sourcePatner: sourcePatnerData });
    }
  }

  checkIsRoundTrip = (locations) => {
    if (locations % 2 !== 0) {
      let isRoundTrip = true;
      const middleIndex = (locations.length - 1) / 2;
      for (let i = 0; i < middleIndex; i++) {
        if (locations[i].locationId !== locations[(locations.length - 1) - i].locationId) {
          isRoundTrip = false;
          break;
        }
      }
      return isRoundTrip;
    } else {
      return false;
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const _this = this;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let sourceEntityId;
        let sourceDestinationEntityId;
        let startValue;
        if (values.routeMappingType === "location") {
          startValue = Object.keys(values.lastdestination);
          sourceEntityId = values.sourceDestination;
          sourceDestinationEntityId = Object.keys(values.lastdestination).length > 1 ? _this.getDestinationLocationId(values.lastdestination) : values.lastdestination[startValue[0]];
        } else if (values.routeMappingType === "zone") {
          startValue = Object.keys(values.zonedestination);
          sourceEntityId = values.sourceZone;
          sourceDestinationEntityId = Object.keys(values.zonedestination).length > 1 ? _this.getDestinationLocationId(values.zonedestination) : values.zonedestination[startValue[0]];
        } else {
          startValue = Object.keys(values.lastdestination);
          sourceEntityId = values.sourceDestination;
          sourceDestinationEntityId = Object.keys(values.lastdestination).length > 1 ? _this.getDestinationLocationId(values.lastdestination) : values.lastdestination[startValue[0]];
        }
        const isZone = values.routeMappingType === "zone";
        // let intermediates;
        // let intermediatesTime = isZone ? _this.getTransitTime(values.zonetransitTime) : _this.getTransitTime(values.transitTime)
        // if (values.tripType === "roundtrip") {
        //   if (!isZone) {
        //     intermediates = _this.roundinterMediateIds(values.lastdestination);
        //     for (let i = intermediates.length - 1; i > 0; i--) {
        //       intermediates.push(intermediates[i - 1]);
        //       intermediatesTime.push(intermediatesTime[i])
        //     }
        //     intermediatesTime.push(intermediatesTime[0]);
        //   } else if (isZone) {
        //     intermediates = _this.roundinterMediateIds(values.zonedestination);
        //     for (let i = intermediates.length - 1; i > 0; i--) {
        //       intermediates.push(intermediates[i - 1]);
        //       intermediatesTime.push(intermediatesTime[i])
        //     }
        //     intermediatesTime.push(intermediatesTime[0]);
        //   }
        // }

        if (!_this.state.isEdit) {
          const { selectedRoute } = _this.state;

          let intermediateDestination = values.tripType === "roundtrip" ? intermediates : (isZone ? _this.interMediateIds(values.zonedestination) : _this.interMediateIds(values.lastdestination));

          let payload = {
            id: selectedRoute.id,
            name: values.routeName,
            partnerId: selectedRoute.partnerId,
            sourceLocationId: sourceEntityId,
            sourceLocation: selectedRoute.sourceLocation,
            sourceZone: selectedRoute.sourceZone,
            destinationLocationId: values.tripType === "roundtrip" ? sourceEntityId : sourceDestinationEntityId,
            parentRouteId: selectedRoute.parentRouteId,
            parentRoute: selectedRoute.parentRoute,
            destinationLocation: selectedRoute.destinationLocation,
            destinationZone: selectedRoute.destinationZone,
            path: null,
            isActive: true,
            routeMapping: selectedRoute.routeMapping,
            routeMappingType: null,
            transitTime: Object.keys(this.state.location).map(item => 1),
            createdAt: moment().unix(),
            updatedAt: moment().unix(),
            routeType: 'LINEHAUL',
            eligibleForTrip: true,
            requireAction: selectedRoute.requireAction,
            updateExistingTHCs: true,
            intermediateDestinationIds: intermediateDestination,
          }

          Modal.confirm({
            title: 'Are you sure?',
            content: 'If there are any THCs linked to this route they will be updated with this operation. Do you wish to continue?. ',
            okText: 'Update',
            cancelText: 'Cancel',
            onOk: () => {

              _this.setState({ isRouteLoadingbox: true, isRouteLoading: true });
              _this.props.updateRoute(selectedRoute.id, payload).then(res => {

                this.props.history.push('/appv2/settings/dashboard/route', { isReset: true });
                // _this.props.fetchRoutesForm(this.state.filterPayload).then(res => {
                //   this.setState({ isRouteLoadingbox: false })
                //   this.setState({ isRouteLoading: false });

                // })
              })
            }
          })

        } else {
          this.setState({ isRouteLoading: true });
          let intermediateDestination = values.tripType === "roundtrip" ? intermediates : (isZone ? _this.interMediateIds(values.zonedestination) : _this.interMediateIds(values.lastdestination));
          // let transitTimes = values.tripType === "roundtrip" ? intermediatesTime : (isZone ? _this.getTransitTime(values.zonetransitTime) : _this.getTransitTime(values.transitTime));
          let destinationLocation = values.tripType === "roundtrip" ? sourceEntityId : sourceDestinationEntityId;
          let payload = {
            name: values.routeName,
            path: null,
            sourcePartnerId: values?.sourcePatner,
            sourceLocationId: sourceEntityId,
            intermediateDestinationIds: intermediateDestination,
            transitTime: intermediateDestination?.length > 0 ? [...intermediateDestination.map(() => 1), 1] : [1],
            eligibleForTrip: true,
            routeType: "LINEHAUL",
            routeMappingType: null,
            destinationLocationId: destinationLocation,
          };
          this.setState({ isRouteLoadingbox: true })
          _this.props.createRoute(payload).then(res => {
            this.props.history.push('/appv2/settings/dashboard/route', { isReset: true });
            // _this.props.fetchRoutesForm(this.state.filterPayload).then(res => {
            //   this.setState({ isRouteLoadingbox: false })
            //   this.setState({ isRouteLoading: false });

            // })
          })
        }
      }
    });
  }

  roundinterMediateIds = (val) => {
    let interMediate = [];
    Object.keys(val).forEach((x, index) => {
      interMediate.push(Number(val[x]));
    })
    return interMediate;
  }

  getDestinationLocationId = (val) => {
    var keys = Object.keys(val);
    return val[keys[keys.length - 1]];
  }

  getTransitTime = (val) => {
    let transitTime = [];
    Object.keys(val).forEach(x => {
      transitTime.push(Number(val[x]));
    })
    return transitTime;
  }

  interMediateIds = (val) => {
    let interMediate = [];
    Object.keys(val).forEach((x, index) => {
      if (Object.keys(val).length - 1 !== index) {
        interMediate.push(Number(val[x]));
      }
    })
    return interMediate;
  }

  goBack = () => {
    id = 0;
    this.setState({ isRouteLoading: true });
    this.props.fetchRoutesForm(this.state.filterPayload).then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        this.setState({ isRouteLoading: false });
        this.props.history.push('/appv2/settings/dashboard/route');
      }
      // this.props.history.goBack();
    })
  }

  handleRoutemappingType = (e) => {
    const { isEdit, routeMappingType } = this.state;
    const { form } = this.props;
    if (!isEdit && e.target.value === routeMappingType) {
      this.setState({ lastRun: true });
    } else {
      form.setFieldsValue({
        keys: { "index-0": 0 },
      });
    }
    if (e.target.value === "location") {
      if (this.state.startlocationsData.length <= 0) {
        // this.getStartLocations(Constants.PARTNER_ID);
      }

      this.setState({ routemappingLocaton: true, disableValue: [], routeMappingType: e.target.value, zonedestination: {}, sourceZone: undefined, zonetransitTime: {} });
    } else {
      this.setState({ routemappingLocaton: false, disableValue: [], routeMappingType: e.target.value, transitTime: {}, destinationPartner: {}, lastdestination: {}, sourceDestination: undefined });
    }
  }

  handleRouteType = (e) => {
    if (e.target.value === "pickup") {
      let tripdata = [{ label: 'Round Trip', value: 'roundtrip' }]
      this.props.form.setFieldsValue({
        tripType: "roundtrip",
        keys: { "index-0": 0 }
      });
      this.setState({ tripType: tripdata, tripTypeadd: false, routeType: e.target.value });
    } else {
      let tripdata = [{ label: 'One Way', value: 'oneway' }, { label: 'Round Trip', value: 'roundtrip' }];
      this.props.form.setFieldsValue({
        tripType: "oneway",
      });
      this.setState({ tripType: tripdata, tripTypeadd: true, lastRun: true, routeType: e.target.value });
    }
  }

  onPartnerSourceChange = (e, value) => {
    let parent = [...this.state.firstLocation];
    if (!value) {
      this.props.form.setFieldsValue({ sourceDestination: undefined });
    }
    if (Number(Constants.PARTNER_ID) !== Number(e)) {
      // this.getStartLocations(e);
    } else {
      this.setState({ startlocations: parent })
    }
  }

  onPartnerChange = (k, e) => {

    let dublicateLoc = [...this.state?.dublicateLocation];
    let locationbox = { ...this.state?.location };
    const finalArray = [...new Set(dublicateLoc.map(item => item.value))];
    if (dublicateLoc.length > 0 && finalArray.includes(e)) {
      dublicateLoc.map(x => {
        if (x.value === e) {
          if (Constants.PARTNER_ID === e) {
            locationbox[k] = this.state?.firstLocation;
          } else {
            locationbox[k] = locationbox[x.index];
          }
        }
      })
    } else {
      dublicateLoc.push({ index: k, value: e });
      // this.getLocations(e, k);
    }
    this.setState({ dublicateLocation: dublicateLoc, location: locationbox })
    const field = `lastdestination[${k}]`
    this.props.form.setFieldsValue({ [field]: undefined })
    this.setState({ indexmaintain: k })
  }

  remove = (k) => {
    const { routemappingLocaton } = this.state;
    const { form } = this.props;
    if (routemappingLocaton) {
      // const destinationPartner = form.getFieldValue('destinationPartner');
      // delete destinationPartner[`${k}`]
      const lastdestination = form.getFieldValue('lastdestination');
      delete lastdestination[`${k}`]
      // const transitTime = form.getFieldValue('transitTime');
      // delete transitTime[`${k}`]
      const keys = form.getFieldValue('keys');
      delete keys[`${k}`]
      form.setFieldsValue({
        keys: { ...keys },
        // destinationPartner,
        lastdestination
        // transitTime,
      });
    } else {
      const keys = form.getFieldValue('keys');
      delete keys[`${k}`]
      const zonedestination = form.getFieldValue('zonedestination');
      delete zonedestination[`${k}`]
      const zonetransitTime = form.getFieldValue('zonetransitTime');
      delete zonetransitTime[`${k}`]
      form.setFieldsValue({
        keys: { ...keys },
        zonedestination,
        zonetransitTime
      });
    }
    this.setState({ addedKey: true });
  };

  add = k => {
    const { routemappingLocaton } = this.state;
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    id = ++id
    const nextKeys = {}
    Object.keys(keys).map(val => {
      if (val === k) {
        nextKeys[val] = keys[val];
        nextKeys['index-' + id] = id;
        if (routemappingLocaton) {
          this.onPartnerChange('index-' + id, Constants.PARTNER_ID);
        } else {
          this.zoneChange('index-' + id)
        }
      } else {
        nextKeys[val] = keys[val];
        if (routemappingLocaton) {
          this.onPartnerChange('index-' + id, Constants.PARTNER_ID);
        } else {
          this.zoneChange('index-' + id)
        }
      }
    })

    form.setFieldsValue({
      keys: nextKeys,
    });
    this.setState({ keys: nextKeys });
  };

  lastDestinationChange = (key, e) => {
    const { form } = this.props;
    const lastdestination = form.getFieldValue('lastdestination');
    const sourceDestination = form.getFieldValue('sourceDestination');
    let currentArray = Object.keys(form.getFieldValue('keys'));
    let currentIndex = currentArray.indexOf(key);
    let defaultArray = [];
    if (key !== null) {
      let lastIndex;
      let previousIndex = currentIndex - 1;
      let nextIndex = currentIndex + 1;
      if (currentIndex > 0) {
        lastIndex = currentArray[previousIndex];
        nextIndex = currentArray[nextIndex];
        defaultArray.push(lastdestination[lastIndex], lastdestination[nextIndex]);
        this.setState({ disableValue: defaultArray })
      } else {
        nextIndex = currentArray[nextIndex];
        defaultArray.push(sourceDestination, lastdestination[nextIndex]);
        this.setState({ disableValue: defaultArray })
      }
    } else {
      let nextIndex = currentArray[0];

      if (lastdestination) {
        defaultArray.push(lastdestination[nextIndex]);
        this.setState({ disableValue: defaultArray })
      }

    }
  }

  zoneChange = (key, e) => {
    const { form } = this.props;
    const zonedestination = form.getFieldValue('zonedestination');
    const sourceZone = form.getFieldValue('sourceZone');
    let currentArray = Object.keys(form.getFieldValue('keys'));
    let currentIndex = currentArray.indexOf(key);
    let defaultArray = [];
    if (key !== null) {
      let lastIndex;
      let previousIndex = currentIndex - 1;
      let nextIndex = currentIndex + 1;
      if (currentIndex > 0) {
        lastIndex = currentArray[previousIndex];
        nextIndex = currentArray[nextIndex]
        defaultArray.push(zonedestination[lastIndex], zonedestination[nextIndex]);
        this.setState({ disableValue: defaultArray })
      } else {
        nextIndex = currentArray[nextIndex];
        defaultArray.push(sourceZone, zonedestination[nextIndex]);
        this.setState({ disableValue: defaultArray })
      }
    } else {
      let nextIndex = currentArray[0]
      defaultArray.push(zonedestination[nextIndex]);
      this.setState({ disableValue: defaultArray })
    }
  }

  onEligibleTripChange = (e) => {
    this.setState({
      eligibleForTrip: e.target.checked,
    });
  }

  onLocationSearch = (value, from, k) => {

    if (value && value.trim() != "") {

      const payload = {
        locName: value
      }

      let listAllRouteEnabledUsers = false;

      if (this.props?.appConfig?.listAllRouteEnabledUsers && localStorage.user) {
        const user = JSON.parse(atob(localStorage.user));
        if (this.props.appConfig.listAllRouteEnabledUsers.includes(String(user.id))) {
          listAllRouteEnabledUsers = true
        }
      }

      if (from === 'sourceloc' && !listAllRouteEnabledUsers) {
        payload['partnerId'] = [Constants.PARTNER_ID]
      }

      actions.fetchAllLocations(payload)
        .then(locationData => {
          if (!locationData) { return }
          if (from === 'destloc') {
            const locationInfo = { ...this.state.location }
            locationInfo[`${k}`] = locationData['locations']
            this.setState({ location: locationInfo })
          } else {
            this.setLocationlevelData(locationData['locations'])
          }
        })
        .catch(e => console.error(e))
    }


  }

  render() {

    const { isEdit, routemappingLocaton, routePermission, isRouteLoadingbox, isRouteLoading, isRouteFullLoad } = this.state;

    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    getFieldDecorator('keys', { initialValue: { ['index-' + id]: id } });
    const keys = getFieldValue('keys');

    let formItems;
    let formItemszone;
    formItems = routemappingLocaton && keys && Object.keys(keys).length > 0 ? Object.keys(keys).map((k, index) => (
      <div key={index}>

        {/* <Col sm={24} md={24} lg={24} xl={24}>
          <Form.Item label="Destination Partner">
            {getFieldDecorator(`destinationPartner[${k}]`, {
              initialValue: !isEdit ? (this.state.destinationPartner ? (this.state.destinationPartner[k] ? (this.state.destinationPartner[k] === Constants.PARTNER_ID ? this.state.destinationPartner[k].toString() : this.state.destinationPartner[k]) : (this.props.partners ? this.props.partners[0].linkPartnerBO.id : undefined)) : undefined) : (this.props.partners ? this.props.partners[0].linkPartnerBO.id : undefined),
              rules: [
                {
                  required: true,
                  message: "Please input Destination Partner"
                }
              ],
            })(
              // <Select disabled={(!routePermission.update && routePermission.view && !isEdit)} placeholder="Select Destination Partner" showSearch filterOption={(input, option) =>
              //   option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              // } showArrow onChange={(e) => this.onPartnerChange(`${k}`, e)}>
              //   {this.props ?.partners ?.map(loc => (
              //     <Select.Option
              //       key={loc.linkPartnerBO.id}
              //       value={loc.linkPartnerBO.id}
              //       label={loc.linkPartnerBO.name}
              //     >
              //       {loc.linkPartnerBO.name}
              //     </Select.Option>
              //   ))}
              // </Select>
              <SuperSelect
                disabled={(!routePermission.update && routePermission.view && !isEdit)}
                showSearch
                allowClear
                filterOption={(v, item) => {

                  return item?.props?.children ? item.props.children.toString().toLowerCase().indexOf(v.toLowerCase()) >= 0 : false

                }}
                placeholder="Select Destination Partner"
                onChange={(e) => this.onPartnerChange(`${k}`, e)}
                onSearch={this.onSearchsef}
                style={{ width: "300px" }}
              >
                {this.state?.partnerData}
              </SuperSelect>
            )}
          </Form.Item>
        </Col> */}

        <Col sm={24} md={24} lg={24} xl={24}>
          <Col sm={24} md={24} lg={24} xl={24}>
            {<Form.Item label={Object.keys(keys).length === (index + 1) ? 'Destination' : `Location #${index + 1}`}>
              {getFieldDecorator(`lastdestination[${k}]`, {
                initialValue: !isEdit ? this.state?.lastdestination ? this.state?.lastdestination[k] : undefined : undefined,
                rules: [
                  {
                    required: true,
                    message: "Please input Destination Location"
                  }
                ],
              })(
                // <Select
                // disabled={(!routePermission.update && routePermission.view && !isEdit)} placeholder="Select Destination" showSearch filterOption={(input, option) =>
                //   option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                // } showArrow onMouseEnter={(e) => this.lastDestinationChange(`${k}`, e)}>
                //   {this.state ?.location[`${k}`] ?.map(loc => (
                //     <Select.Option
                //       key={loc.id}
                //       value={loc.id}
                //       label={loc.name}
                //       disabled={this.state?.disableValue?.includes(loc.id) ? true : false}
                //     >
                //       {loc.name} ({loc.entityType})
                //     </Select.Option>
                //   ))}
                // </Select>
                <SuperSelect
                  disabled={(!routePermission.update && routePermission.view && !isEdit)}
                  showSearch
                  allowClear
                  filterOption={false}
                  // filterOption={(v, item) => {
                  //   return item.props.children.toString().toLowerCase().indexOf(v.toLowerCase()) >= 0
                  // }}
                  // loading={this.state.destinationLocationLoader}
                  placeholder="Select Destination Location"
                  // mode="multiple"
                  // onMouseEnter={(e) => this.lastDestinationChange(`${k}`, e)}
                  // onChange={this.onChangesef}
                  onSearch={(value) => this.onLocationSearch(value, "destloc", k)}
                  style={{ width: "300px" }}
                >
                  {this.setLocationEndData(this.state?.location[`${k}`])}
                </SuperSelect>
              )}
            </Form.Item>}
          </Col>
          <Col sm={8} md={8} lg={8} xl={8}></Col>
          {/* <Col sm={13} md={13} lg={13} xl={13}>
            <Form.Item>
              {getFieldDecorator(`transitTime[${k}]`, {
                initialValue: !isEdit ? this.state.transitTime ? this.state.transitTime[k] : undefined : undefined,
                rules: [
                  {
                    required: true,
                    message: 'Please input Transit Time(hr)!',
                  },
                ],
              })(
                <Input
                  placeholder="Transit Time(hr)"
                  disabled={(!routePermission.update && routePermission.view && !isEdit)}
                />
              )}
            </Form.Item>
          </Col> */}
          {this.state.tripTypeadd === true && <Col sm={3} md={3} lg={3} xl={3}>
            <Col sm={12} md={12} lg={12} xl={12} style={{ padding: 6 }}>
              <Button disabled={(!routePermission.update && routePermission.view && !isEdit)} type="primary" shape="circle" size="small" icon="plus" onClick={() => this.add(k)}>
              </Button>
            </Col>
            {Object.keys(keys).length > 1 && <Col sm={12} md={12} lg={12} xl={12} style={{ padding: 6 }}>
              <Button disabled={(!routePermission.update && routePermission.view && !isEdit)} type="danger" shape="circle" size="small" icon="minus" onClick={() => this.remove(k)}>
              </Button>
            </Col>}
          </Col>}
        </Col>
      </div>
    )) : null;

    formItemszone = !routemappingLocaton && keys && Object.keys(keys).length > 0 ? Object.keys(keys).map((k, index) => (
      <div key={index}>
        <Col sm={24} md={24} lg={24} xl={24}>
          <Col sm={24} md={24} lg={24} xl={24}>
            <Form.Item label={Object.keys(keys).length === (index + 1) ? 'Destination' : `Zone #${index + 1}`}>
              {getFieldDecorator(`zonedestination[${k}]`, {
                initialValue: !isEdit ? this.state?.zonedestination ? this.state?.zonedestination[k] : undefined : undefined,
                rules: [
                  {
                    required: true,
                    message: "Select Source Zone Destination"
                  }
                ],
              })(
                // <Select disabled={(!routePermission.update && routePermission.view && !isEdit)} placeholder="Select Source Zone Destination" showSearch filterOption={(input, option) =>
                //   option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                // } showArrow onMouseEnter={(e) => this.zoneChange(`${k}`, e)}>
                //   {this.props ?.zones ?.map(zone => (
                //     <Select.Option
                //       key={zone.id}
                //       value={zone.id}
                //       label={zone.name}
                //       disabled={this.state?.disableValue?.includes(zone.id)? true : false}
                //     >
                //       {zone.name}
                //     </Select.Option>
                //   ))}
                // </Select>
                <SuperSelect
                  disabled={(!routePermission.update && routePermission.view && !isEdit)}
                  showSearch
                  allowClear
                  filterOption={(v, item) => {

                    return item.props.children.toString().toLowerCase().indexOf(v.toLowerCase()) >= 0

                  }}
                  placeholder="Select Source Zone Destination"
                  // mode="multiple"
                  onMouseEnter={(e) => this.zoneChange(`${k}`, e)}
                  // onChange={this.onChangesef}
                  style={{ width: "300px" }}
                >
                  {this.state?.zones}
                </SuperSelect>
              )}
            </Form.Item>
          </Col>
          <Col sm={8} md={8} lg={8} xl={8} ></Col>
          <Col sm={13} md={13} lg={13} xl={13} >
            <Form.Item>
              {getFieldDecorator(`zonetransitTime[${k}]`, {
                initialValue: !isEdit ? this.state?.zonetransitTime ? this.state?.zonetransitTime[k] : undefined : undefined,
                rules: [
                  {
                    required: true,
                    message: 'Please input Zone Transit Time(hr)!',
                  },
                ],
              })(
                <Input
                  placeholder="Transit Zone Time(hr)"
                  style={{ width: '100%' }}
                  disabled={(!routePermission.update && routePermission.view && !isEdit)}
                />
              )}
            </Form.Item>
          </Col>
          {this.state.tripTypeadd === true &&
            <Col sm={3} md={3} lg={3} xl={3}>
              <Col sm={12} md={12} lg={12} xl={12} style={{ padding: 6 }}>
                <Button disabled={(!routePermission.update && routePermission.view && !isEdit)} type="primary" shape="circle" size="small" icon="plus" onClick={() => this.add(k)}>
                </Button>
              </Col>
              {Object.keys(keys).length > 1 && <Col sm={12} md={12} lg={12} xl={12} style={{ padding: 6 }}>
                <Button disabled={(!routePermission.update && routePermission.view && !isEdit)} type="danger" shape="circle" size="small" icon="minus" onClick={() => this.remove(k)}>
                </Button>
              </Col>}
            </Col>}
        </Col>
      </div>
    )) : null;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelAlign: "left",
      colon: false
    };

    return (
      <>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} >
          <Card className="CustomerDashBoard" title={<h3> <Icon onClick={this.goBack} style={{ margin: 15, marginLeft: 0 }} type="arrow-left" /> {isEdit ? 'Create' : 'Update'} Route</h3>}>
            <Spin spinning={isRouteLoading}>
              <Spin spinning={isRouteLoadingbox}>
                <Spin spinning={isRouteFullLoad}>
                  <Row>
                    <Col span={12}>
                      <Form.Item label="Route Name">
                        {getFieldDecorator('routeName', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input route name!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Enter Route name"
                            disabled={(!routePermission.update && routePermission.view && !isEdit)}
                          />
                        )}
                      </Form.Item>
                      {/* <Form.Item label="Route Type">
                        {getFieldDecorator('routeType', {
                          initialValue: !isEdit ? this.state.routeType ? this.state.routeType : "linehaul" : "linehaul",
                          rules: [
                            {
                              required: true,
                              message: `Route Type can't be blank!
                            }
                          ],
                        })(
                          <Radio.Group onChange={this.handleRouteType} disabled={(!routePermission.update && routePermission.view && !isEdit)}>
                            <Radio value={'linehaul'}>Line Haul</Radio>
                            <Radio value={'pickup'}>Pickup</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item> */}
                      <Divider orientation="left">Source</Divider>

                      {/* {routemappingLocaton && <Form.Item label="Source Partner">
                        {getFieldDecorator('sourcePatner', {
                          initialValue: !isEdit ? (this.state?.sourcePatner ? (this.state?.sourcePatner === Constants.PARTNER_ID ? this.state?.sourcePatner.toString() : this.state.sourcePatner) : undefined) : (this.props.partners ? this.props.partners[0].linkPartnerBO.id : undefined),
                          rules: [
                            {
                              required: true,
                              message: "Please input Source Partner"
                            }
                          ],
                        })(
                          <SuperSelect
                            disabled={(!routePermission.update && routePermission.view && !isEdit)}
                            showSearch
                            allowClear
                            filterOption={(v, item) => {

                              return item?.props?.children ? item.props.children.toString().toLowerCase().indexOf(v.toLowerCase()) >= 0 : false

                            }}
                            placeholder="Select Source Partner"
                            onChange={(e) => this.onPartnerSourceChange(e, false)}
                            onSearch={this.onSearchsef}
                            style={{ width: "300px" }}
                          >
                            {this.state?.partnerData}
                          </SuperSelect>
                        )}
                      </Form.Item>} */}

                      {routemappingLocaton && <Form.Item label="Source">
                        {getFieldDecorator('sourceDestination', {
                          initialValue: !isEdit ? this.state?.sourceDestination ? this.state?.sourceDestination : undefined : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please input source location"
                            }
                          ],
                        })(
                          <SuperSelect
                            disabled={(!routePermission.update && routePermission.view && !isEdit)}
                            showSearch
                            loading={this.state.locationLoader}
                            allowClear
                            filterOption={false}
                            // filterOption={(v, item) => {
                            //   return item.props.children.toString().toLowerCase().indexOf(v.toLowerCase()) >= 0
                            // }}
                            placeholder="Select Source Location"
                            // onMouseEnter={(e) => this.lastDestinationChange(null, e)}
                            style={{ width: "300px" }}
                            onSearch={(val) => this.onLocationSearch(val, 'sourceloc', null)}
                          >
                            {this.state?.startlocationsData}
                          </SuperSelect>
                        )}
                      </Form.Item>}
                      {!routemappingLocaton && <Form.Item label="Source Zone">
                        {getFieldDecorator('sourceZone', {
                          initialValue: !isEdit ? this.state.sourceZone ? this.state.sourceZone : undefined : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please input Source Zone"
                            }
                          ],
                        })(
                          <SuperSelect
                            disabled={(!routePermission.update && routePermission.view && !isEdit)}
                            loading={this.state.zoneLoading}
                            showSearch
                            allowClear
                            filterOption={(v, item) => {

                              return item.props.children.toString().toLowerCase().indexOf(v.toLowerCase()) >= 0

                            }}
                            placeholder="Select Source Zone"
                            onMouseEnter={(e) => this.zoneChange(null, e)}
                            style={{ width: "300px" }}
                          >
                            {this.state?.zones}
                          </SuperSelect>
                        )}
                      </Form.Item>}
                      <Divider orientation="left">
                        Destination
                      </Divider>
                      {formItems}
                      {formItemszone}
                      {/* <Divider /> */}
                      {/* <Form.Item label="Eligible for Trip">
                        {getFieldDecorator('eligibleForTrip', {
                          initialValue: !isEdit ? this.state.eligibleForTrip ? this.state.eligibleForTrip : false : false,
                          valuePropName: 'checked',
                          rules: [
                            {
                              required: false,
                              message: "Eligible for Trip"
                            }
                          ],
                        })(
                          <Checkbox
                            onChange={this.onEligibleTripChange}
                          >
                            Eligible for Trip
                    </Checkbox>
                        )}
                      </Form.Item> */}
                    </Col>
                  </Row>
                </Spin>
              </Spin>
            </Spin>
          </Card>
          <Card>
            <Spin spinning={isRouteLoading}>
              <Spin spinning={isRouteLoadingbox}>
                <Spin spinning={isRouteFullLoad}>
                  <Row>
                    <Col span={2} offset={18} >
                      <Button disabled={(!routePermission.update && routePermission.view && !isEdit)} size="large" onClick={this.goBack} >CANCEL</Button>
                    </Col>
                    <Col span={3}>
                      <Button disabled={(!routePermission.update && routePermission.view && !isEdit)} size="large" htmlType="submit" type="primary">{isEdit ? 'CREATE' : 'UPDATE'}</Button>
                    </Col>
                  </Row>
                </Spin>
              </Spin>
            </Spin>
          </Card>
        </Form>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  partners: state.header?.partnerHeaderDetails?.partners,
  selectedRoute: state.settingsRoute.selectedRoute,
  appConfig: state?.app?.configurations
});

const mapDispatchToProps = dispatch => ({
  getZones: () => dispatch(actions.getZones()),
  getStartLocationData: (param, entityType) => dispatch(actions.getStartLocationData(param, entityType)),
  getRoute: (id) => dispatch(actions.getRoute(id)),
  createRoute: (payload) => dispatch(actions.createRoute(payload)),
  updateRoute: (id, payload) => dispatch(actions.updateRoute(id, payload)),
  fetchRoutesForm: param => dispatch(actions.fetchRoutesForm(param)),
  getPartnerDetails: params => dispatch(actionspartner.getPartnerDetails(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Route', onValuesChange: (props, val) => { } })(RouteForm));