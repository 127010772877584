import { Button, Divider, Icon, Input, Select, Spin, Table, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlowTypes } from "../../../common/utils/constants";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import { SoundNotificationService } from "../../../services/sound.service";
import BulkOperation from "../../shared-components/bulk-operation";
import WaybillLink from "../../shared-components/waybill-link";
import * as DrsService from "./service";

export default function CreateDRSComponent(props) {

    const { drsDetails, callDRSDetails, isAllowedToAddWaybills, drsCode, flowType, SetDRSCode, isAllowedToDelinkWaybills } = props;
    const { fetchUnassignedCount, delinkWaybill, scanWaybill, SetDrsDetails, fetchUnassignedList, createDRS, callUploadDrs, generateDRSCode } = props;
    const sns = new SoundNotificationService();

    const navigate = useNavigate();
    const scanInputRef = useRef();

    const [users, SetUsers] = useState([]);
    const [waybillInput, SetWaybillInput] = useState("");
    const [drsUser, SetDrsUser] = useState(undefined);
    const [unassignedCount, SetUnassignedCount] = useState([]);
    const [unassignedListObject, SetUnassignedListObject] = useState([]);
    const [pendingListLoader, setPendingListLoader] = useState(false);

    const config = useSelector(state => state.app.configurations);

    const disableWaybillDelink = flowType == FlowTypes.FORWARD && config.block_drs_consignment_delink == 'true';

    const pendingColumns = [
        {
            title: "AWB No.",
            dataIndex: "waybillNo",
            key: 1,
            render: data => <WaybillLink>{data}</WaybillLink>
        },
        {
            title: "Customer",
            dataIndex: "customerName",
            key: 2
        },
        {
            title: "Destination",
            dataIndex: "destination",
            key: 3,
            render: destination => (<div>{destination?.cityCode} - {destination?.pincode}</div>)
        },
        {
            title: "Action",
            dataIndex: "waybillNo",
            key: 4,
            width: 60,
            render: awb => (
                (drsDetails?.drsCode && isAllowedToAddWaybills) ?
                    <div className="flex-box justify-content-center">
                        <Icon type="plus-circle" theme="twoTone" twoToneColor={"#50b154"} style={{ fontSize: 18 }}
                            onClick={_ => callScanWaybill(awb, "SCAN")} />
                    </div> :
                    undefined
            )
        }
    ]

    const scannedColumns = [
        {
            title: "AWB No.",
            dataIndex: "waybillNo",
            key: 1,
            render: data => <WaybillLink>{data}</WaybillLink>
        },
        {
            title: "Customer",
            dataIndex: "customerName",
            key: 2
        },
        {
            title: "Destination",
            dataIndex: "destination",
            key: 3,
            render: destination => (<div>{destination?.cityCode} - {destination?.pincode}</div>)
        },
        {
            title: "Action",
            dataIndex: "waybillNo",
            key: 4,
            width: 60,
            render: awb => (
                (drsDetails?.drsCode && isAllowedToDelinkWaybills) ?
                    <div className="flex-box justify-content-center">
                        <Tooltip title={disableWaybillDelink ? "De-link option is blocked. Please contact LoadShare support for any doubts" : undefined}>
                            <Icon type="minus-circle" theme="twoTone" twoToneColor={disableWaybillDelink ? "#a7a7a7" : "#f44337"}
                                style={{
                                    fontSize: 18,
                                    cursor: disableWaybillDelink ? 'not-allowed' : 'pointer'
                                }}
                                onClick={_ => disableWaybillDelink ? undefined : callDelinkWaybill(awb)} />
                        </Tooltip>
                    </div> :
                    undefined
            )
        }
    ]

    useEffect(() => {
        if (flowType == FlowTypes.FORWARD) {
            fetchPendingCount();
            fetchPendingList(1);
        }
    }, []);

    const refetchUsersList = _ => {
        SetUsers(false);

        DrsService.fetchFeList()
            .then(resp => {
                if (resp?.userDetails) {
                    SetUsers(resp?.userDetails)
                }
            })
            .catch(_ => SetUsers([]))

    }

    const callCreateDRS = _ => {
        createDRS(drsUser)
            .then(_ => {
                notifyApiSuccess(`DRS for ${drsUser.label} created successfully!`);
                SetDrsDetails(flowType == FlowTypes.RTO ? {
                    rtoDrsCode: drsCode,
                    rtoDrsUser: {
                        name: drsUser?.label
                    }
                } : {
                    drsCode: drsCode,
                    drsUser: {
                        name: drsUser?.label
                    }
                });
                sns.playSuccess();
            })
            .catch(response => {
                notifyApiError(response?.errors);
                sns.playWarning();
            })
    }

    const fetchPendingCount = _ => {
        fetchUnassignedCount()
            .then(({ consignmentCount }) => {
                if (consignmentCount) {
                    SetUnassignedCount(consignmentCount);
                }
            });
    }

    const fetchPendingList = (pageNo = 1) => {
        fetchUnassignedList(pageNo, 5)
            .then(response => {
                SetUnassignedListObject(response)
                setPendingListLoader(false)
            })
            .catch(error => { setPendingListLoader(false) });
    }

    const callScanWaybill = waybill => {

        // Making input component as read only and bringing back focus
        // after api response to prevent multiple api calls.
        let scanInput = document.getElementById("scanInput")

        if (scanInput) {
            scanInput.blur()
            scanInput.setAttribute("readonly", "readonly");
        }

        if (!drsDetails?.drsCode) {
            if (scanInput) {
                scanInput.focus();
                scanInput.removeAttribute("readonly");
            }
            return notifyApiError("Create DRS and scan waybill!");
        }
        else if (!waybill || waybill?.trim() == "") {
            if (scanInput) {
                scanInput.focus();
                scanInput.removeAttribute("readonly");
            }
            return notifyApiError("Enter Waybill Number to scan!");
        }
        else if (!/^[a-zA-Z0-9_-]*$/.test(waybill?.trim())) {
            if (scanInput) {
                scanInput.focus();
                scanInput.removeAttribute("readonly");
            }
            return notifyApiError("Entered Waybill Number is invalid!");
        }

        scanWaybill(waybill?.trim())
            .then(response => {
                SetUnassignedCount(c => --c);
                SetWaybillInput('');
                SetDrsDetails(current => {
                    var temp = { ...current };
                    temp.consignments ? temp.consignments.push(response) : temp.consignments = [response];
                    return temp;
                });
                if (scanInput) {
                    scanInput.focus();
                    scanInput.removeAttribute("readonly");
                }
                SetUnassignedListObject([])
                sns.playSuccess();
            })
            .catch(_ => {
                if (scanInput) {
                    scanInput.focus();
                    scanInput.removeAttribute("readonly");
                }
                sns.playWarning();
            })
    }

    const callDelinkWaybill = waybill => {
        if (waybill && typeof delinkWaybill == "function") {
            delinkWaybill(waybill)
                .then(_ => {
                    SetUnassignedCount(c => ++c);
                    SetDrsDetails(current => {
                        var temp = { ...current };
                        var _consignments = temp.consignments;
                        return {
                            ...current,
                            consignments: _consignments.filter(c => c.waybillNo != waybill)
                        }
                    });
                    SetUnassignedListObject([])
                    sns.playSuccess();
                })
                .catch(_ => sns.playWarning())
        }
    }

    const callbackAfterBulkInscan = _ => {
        if (flowType == FlowTypes.FORWARD) {
            fetchPendingCount();
        }
        callDRSDetails(drsDetails.drsCode);
    }

    const refreshPendingList = () => {
        setPendingListLoader(true)
        let pNo = unassignedListObject?.pageNo;
        fetchPendingList(pNo);
    }

    return (
        <div className="flex-box flex-gap-l">
            <div className="flex-column white-bg flex-1 border-radius-s">
                <div className="spacer-m font-size-l-1 text-bold">
                    {
                        drsDetails?.drsCode ?
                            "Update DRS" :
                            "Create DRS"
                    }
                </div>
                <Divider style={{ margin: 0 }} />
                <div className="spacer-m flex-column flex-gap-l">
                    <div className="flex-box flex-gap-m align-items-center">
                        <div className="flex-1"> DRS ID </div>
                        <div className="flex-2">
                            <Input value={drsCode} placeholder="Enter DRS ID" disabled={drsDetails?.drsCode !== undefined}
                                onChange={e => SetDRSCode(e?.target?.value)}
                                suffix={
                                    !drsDetails?.drsCode ?
                                        <Icon type="sync"
                                            onClick={_ => generateDRSCode()} /> :
                                        undefined
                                } />
                        </div>
                    </div>
                    <div className="flex-box flex-gap-m align-items-center">
                        <div className="flex-1"> Employee <span className="error-color">*</span> </div>
                        <div className="flex-2">
                            {
                                drsDetails?.drsCode ?
                                    <Input disabled={true} value={drsDetails?.drsUser?.name} /> :
                                    <Select placeholder="Select Employee" style={{ width: "100%" }} showSearch={true}
                                        labelInValue={true} value={drsUser} onChange={SetDrsUser}
                                        onFocus={refetchUsersList} loading={users === false}
                                        filterOption={(input, option) => option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}>
                                        {
                                            users === false ?
                                                <Select.Option disabled={true} key={-1} value={-1} >
                                                    <div className="flex-box align-items-center flex-gap-l">
                                                        <Spin spinning={true} />
                                                        <div>Fetching users</div>
                                                    </div>
                                                </Select.Option> :
                                                users && users?.map(e => (
                                                    <Select.Option key={e.id} value={e.id} >
                                                        {e.name}
                                                    </Select.Option>
                                                ))
                                        }
                                    </Select>
                            }
                        </div>
                    </div>
                    <div className="flex-box flex-gap-m justify-content-flex-end align-items-center">
                        {
                            drsDetails?.drsCode == undefined &&
                            <>
                                <Button className="lsn-btn-secondary" onClick={_ => navigate(-1)}>
                                    Cancel
                                </Button>
                                <Button onClick={callCreateDRS} className="lsn-btn-primary" disabled={drsUser === undefined}>
                                    Create
                                </Button>
                            </>
                        }
                    </div>
                </div>
                <Divider style={{ margin: 0 }} />
                {
                    drsDetails?.drsCode &&
                    <div className="spacer-m flex-column flex-gap-xl">
                        <div className="text-bold font-size-m-3">
                            Scan the waybills for {drsDetails?.drsCode}
                        </div>
                        <div className="flex-box flex-gap-xl align-items-center">
                            <Input className="flex-4" placeholder={isAllowedToAddWaybills ? `Scan the waybills for ${drsDetails?.drsCode}` : "Waybills scan disabled!"}
                                value={waybillInput} onChange={e => SetWaybillInput(e?.target?.value)}
                                disabled={!isAllowedToAddWaybills} ref={scanInputRef}
                                onPressEnter={e => callScanWaybill(e?.target?.value, "INPUT")} id="scanInput" size="large" />
                            <div className="element-splitter"> OR </div>
                            {
                                isAllowedToAddWaybills ?
                                    <BulkOperation
                                        buttonLabel="Bulk DRS"
                                        title="Bulk DRS"
                                        bulkType="Drsscan"
                                        sampleDownloadLink={"https://loadshare-v2.s3.ap-south-1.amazonaws.com/dev/DRS_Bulk_upload.xlsx"}
                                        objKey="url"
                                        path="bulk_drsscans"
                                        syncToServerAction="UPLOAD_DRSSCAN"
                                        uploadMethod={callUploadDrs}
                                        uploadMethodParams={flowType == FlowTypes.RTO ? {
                                            rtoDrsCode: drsDetails?.drsCode
                                        } : {
                                            drsCode: drsDetails?.drsCode
                                        }}
                                        parseResponseObj={response => {
                                            var t = { ...response };
                                            t.failureCount = t.unSuccessfulCount;
                                            t.successCount = t.successfulCount;
                                            t.sync = true;
                                            return t;
                                        }}
                                        callback={callbackAfterBulkInscan}
                                    /> :
                                    <Button size="large" className="lsn-btn-primary" disabled={true}>
                                        Bulk DRS
                                    </Button>
                            }
                        </div>
                        <div className="flex-box flex-gap-m justify-content-flex-end align-items-center">
                            <Button size="large" onClick={_ => navigate(-1)} className="lsn-btn-secondary">
                                Done
                            </Button>
                            <Button size="large" onClick={_ => callScanWaybill(waybillInput, "INPUT")} className="lsn-btn-primary">
                                Add
                            </Button>
                        </div>
                    </div>
                }
            </div>
            <div className="flex-column flex-gap-l flex-1">
                <div className="white-bg border-radius-s">
                    <div className="spacer-m font-size-m-3 text-bold">
                        Selected AWB (Count - {drsDetails?.consignments ? drsDetails?.consignments.length : 0})
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <div className="spacer-m">
                        <Table columns={scannedColumns} dataSource={drsDetails?.consignments || []}
                            size="small" rowKey="waybillNo"
                            pagination={{
                                total: drsDetails?.consignments ? drsDetails?.consignments.length : 0,
                                pageSize: 5,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                            }} />
                    </div>

                </div>
                {
                    flowType != FlowTypes.RTO &&
                    <div className="white-bg border-radius-s">
                        <div className="flex align-items-center">
                            <div className="spacer-m font-size-m-3 text-bold">
                                Pending AWB (Count - {unassignedCount})
                            </div>
                            <Button className="lsn-btn-primary" icon="reload" onClick={refreshPendingList}
                                loading={pendingListLoader} disabled={!drsDetails?.drsCode} >Reload Pending list</Button>
                        </div>
                        <Divider style={{ margin: 0 }} />
                        <Spin spinning={pendingListLoader}>
                            {unassignedListObject?.consignments?.length &&
                                <div className="spacer-m">
                                    <Table columns={pendingColumns} dataSource={unassignedListObject?.consignments}
                                        size="small" rowKey="waybillNo"
                                        pagination={{
                                            onChange: fetchPendingList,
                                            total: unassignedCount,
                                            current: unassignedListObject?.pageNo,
                                            pageSize: unassignedListObject?.pageSize,
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                                        }} />
                                </div>
                            }
                        </Spin>
                    </div>
                }
            </div>
        </div>
    )
}