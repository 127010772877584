import React, { useState } from 'react';
import * as drsClosureAction from "../action";
import { Input, Card, Button, Modal } from 'antd';
import "../pod-drs-closure.styles.scss";
import { SoundNotificationService } from '../../../../services/sound.service';
import { notifyApiError, notifyApiSuccess } from '../../../../common/utils/utils';
import { checkHttpStatus } from '../../../../common/apiService/utils';
import TamperedReasonSelect from '../../../common/tampered-reason-select';
import * as moment from "moment";
import { useSelector } from "react-redux";
import ScanActionRequiredPopupModal from '../../../shared-components/scan-action-required-modal';
import SuspiciousUndelAction from '../../../operations/drs/components/suspicious.actions';

const ScanCard = ({ toggleLoader, handleScanRes, validateFeRes }) => {

    const soundNotificationService = new SoundNotificationService();

    const user = JSON.parse(atob(localStorage.user));

    const config = useSelector((state) => state?.app?.configurations)

    const [scannedAwb, setScannedAwb] = useState("");
    const [scanApiRes, setScanApiRes] = useState({});
    const [showActionModal, setActionModel] = useState(false);
    const [actionUrl, setActionUrl] = useState("");

    const [lastInputTimeRef, setLastInputTimeRef] = useState(Date.now());


    const scanResCardData = [
        {
            checkKey: scanApiRes?.waybillNo,
            displayText: 'Waybill No',
            dataKey: <div>{scanApiRes?.waybillNo}</div>,
        },
        {
            checkKey: scanApiRes?.consignmentStatus,
            displayText: 'Status',
            dataKey: <div>{scanApiRes?.consignmentStatus}</div>
        },
        {
            checkKey: scanApiRes?.attempts,
            displayText: 'No. of Attempts',
            dataKey: <div>{scanApiRes?.attempts}</div>
        },
        {
            checkKey: scanApiRes?.shipmentDays,
            displayText: 'Ageing',
            dataKey: <div>{scanApiRes?.shipmentDays}</div>
        },
        {
            checkKey: scanApiRes?.rescheduleDate,
            displayText: 'Rescheduled For',
            dataKey: <div>{moment(scanApiRes?.rescheduleDate).format('DD/MM/YYYY HH:mm')}<br />
                <small>{moment(scanApiRes?.rescheduleDate).fromNow()}</small></div>
        },
        {
            checkKey: scanApiRes?.reasonDescription,
            displayText: 'Undel Reason',
            dataKey: <div>{scanApiRes?.reasonDescription}</div>
        },
        {
            checkKey: scanApiRes?.suspiciousReason,
            displayText: 'Suspicious Reason',
            dataKey: <div>{scanApiRes?.suspiciousReason}</div>
        },
        {
            checkKey: scanApiRes?.shipmentAction,
            displayText: 'NDR Instruction',
            dataKey: <div>{{
                "RE_ATTEMPT": "Reattempt",
                "PRIORITY": "Priority"
            }[scanApiRes?.shipmentAction]}</div>
        }
    ]

    const handleCancel = e => {
        setActionModel(false)
    };

    const handleAwScan = (enteredAwb) => {
        // Returning from function if no waybill number is entered
        // and enter button is clicked
        if (!enteredAwb) {
            return
        }

        // Throwing error message when same waybill is scanned back to back.
        if (enteredAwb == scanApiRes?.waybillNo && scanApiRes.scanStatus != 'FAILED') {
            notifyApiError("Waybill " + scanApiRes?.waybillNo + " is already scanned")
            return
        }

        toggleLoader(true)

        let shipmentList = [...validateFeRes.undelShipment]
        shipmentList = shipmentList.concat(validateFeRes.ofdShipment)

        const waybillDetails = shipmentList.find(waybillObj => waybillObj.waybillNo === enteredAwb)

        let currTime = Date.now();
        const prevTime = lastInputTimeRef;
        const timeDifference = currTime - prevTime;
        let scanType;

        if (timeDifference < 50) {
            scanType = 'BARCODE_SCANNER';
        }
        else {
            scanType = 'KEYBOARD';
        }

        // if (
        //     user?.location?.id &&
        //     config?.shipmentRejectOrReattemptEnabledLocations &&
        //     Array.isArray(config?.shipmentRejectOrReattemptEnabledLocations) &&
        //     (config?.shipmentRejectOrReattemptEnabledLocations.includes(user.location.id.toString()) || config?.shipmentRejectOrReattemptEnabledLocations.includes("0")) &&
        //     config?.shipmentRejectOrReattemptEnabledCustomer &&
        //     Array.isArray(config?.shipmentRejectOrReattemptEnabledCustomer) &&
        //     config?.shipmentRejectOrReattemptEnabledCustomer?.some(
        //         (customerData) => customerData.customerId == waybillDetails?.customerId
        //     )
        // ) 

        if (true) {

            drsClosureAction.undelScan(enteredAwb, scanType)
                .then((data) => {

                    if (data) {
                        if (data.deepLinkUrl && data.deepLinkUrl.length > 0) {
                            setActionModel(true)
                            setActionUrl(data.deepLinkUrl)
                        } else {
                            const scanResObj = {
                                waybillNo: data.waybillNumber,
                                customerId: waybillDetails?.customerId,
                                consignmentStatus: data.status,
                                attempts: data.numberOfAttempts,
                                shipmentDays: data.aging,
                                actions: data.actions && Array.isArray(data.actions) ? data.actions.map((action) => action.toLowerCase()) : [],
                                shipmentAction: data?.attributes?.[0]?.type,
                                consigneeContactNumber: data?.consigneeContactNumber
                            }

                            if (data.undelReason) {
                                scanResObj['reasonDescription'] = data.undelReason
                            }

                            if (data.attributes && data.attributes.length > 0) {
                                const suspiciousAttr = data.attributes.find((shipmentInfo) => shipmentInfo.type.toLowerCase() === "suspicious")
                                if (suspiciousAttr) {
                                    scanResObj['suspiciousReason'] = suspiciousAttr.reason
                                    scanResObj['title'] = <div className='error-color' style={{ fontSize: "20px" }}>Suspicious Undelivery - Needs your attention</div>
                                }
                            }

                            setScanApiRes(scanResObj)
                            handleScanRes(enteredAwb)
                            notifyApiSuccess("Successfully Completed")
                            soundNotificationService.playSuccess()
                        }
                    }
                })
                .catch((error) => {
                    soundNotificationService.playGeneralWarning()
                    if (error && error?.errorCode == "E718") {
                        const data = JSON.parse(error?.errorResponse);
                        const scanResObj = {
                            waybillNo: data?.waybillNumber,
                            consignmentStatus: "UNDEL",
                            attempts: data?.numberOfAttempts,
                            shipmentDays: data?.aging,
                            actions: data?.actions && Array.isArray(data.actions) ? data.actions.map((action) => action.toLowerCase()) : [],
                            consigneeContactNumber: data?.consigneeContactNumber,
                            scanStatus: "FAILED",
                            drsCode: data?.drsCode
                        }

                        if (data?.undelReason) {
                            scanResObj.reasonDescription = data?.undelReason
                        }

                        if (data?.undelScanAttributes && data?.undelScanAttributes.length > 0) {
                            const suspiciousAttr = data?.undelScanAttributes.find((shipmentInfo) => shipmentInfo.type.toLowerCase() === "suspicious")
                            if (suspiciousAttr) {
                                scanResObj.suspiciousReason = suspiciousAttr.details
                            }
                        }

                        setScanApiRes(scanResObj)
                    }
                })

        } else {

            const scanReqBody = {
                request: [
                    {
                        consignmentScan: {
                            waybillNo: enteredAwb,
                            consignment: { waybillNo: enteredAwb },
                            scanType: "INWARD_SCAN",
                            scanInputType: scanType,
                        },
                        scanInputType: scanType,
                        type: "INWARD_SCAN",
                        referenceId: enteredAwb
                    }
                ]
            }

            drsClosureAction.scanWaybill(scanReqBody)
                .then((res) => {

                    if (checkHttpStatus(res?.status?.code)) {
                        if (res?.response?.responses?.length > 0 && res?.response?.responses[0]?.sync) {
                            handleScanRes(enteredAwb)

                            // Calculating shipment aging
                            const shipmentDay = Math.round(moment.duration(moment().diff(moment(new Date(res.response.responses[0].scanResponse.createdAt), 'YYYY-MM-DD'))).asDays())

                            const scanApiRes = res.response.responses[0]

                            const scanResObj = {
                                waybillNo: scanApiRes?.consignmentScanBO?.waybillNo,
                                customerId: scanApiRes?.consignmentScanBO?.customerId,
                                consignmentStatus: scanApiRes?.consignmentScanBO?.consignment?.consignmentStatus,
                                attempts: scanApiRes?.consignmentScanBO?.consignment?.attempts,
                                shipmentDays: shipmentDay > 10 ? ">10" : shipmentDay,
                                reasonDescription: scanApiRes?.consignmentScanBO?.consignment?.lastReason?.reasonDescription,
                                shipmentAction: scanApiRes?.shipmentAction,
                                rescheduleDate: scanApiRes?.scanResponse?.rescheduleDate
                            }

                            setScanApiRes(scanResObj)
                            soundNotificationService.playSuccess()
                            notifyApiSuccess(res?.response?.responses[0]?.reason)

                        } else {
                            console.error("scanWaybill api failed with error->", res?.response?.responses[0].reason)
                            notifyApiError(res?.response?.responses[0]?.reason)
                            soundNotificationService.playGeneralWarning()
                        }
                    } else {
                        soundNotificationService.playGeneralWarning()
                        console.error(res?.status?.message)
                        notifyApiError("Error while scanning waybill please contact support "
                            + res?.status?.message)
                    }

                })
                .catch((error) => {
                    notifyApiError("Error while scanning waybill please contact support ")
                    console.error("Error will calling scanWaybill api", error)
                })
        }

        setScannedAwb("")
        toggleLoader(false)
    }

    const handleChange = (event) => {
        setScannedAwb((event.target.value).trim());
        setLastInputTimeRef(Date.now());
    }

    const handleActionClick = (actionType) => {

        if (actionType == 'reject') {

            drsClosureAction.cancelOrder(scanApiRes?.waybillNo)
                .then(() => {
                    notifyApiSuccess("Successfully Completed")
                    setScanApiRes({})
                })
                .catch((error) => {
                    console.error("Cancel order api failed with following error", error);
                })

        } else if (actionType == 'reattempt') {

            drsClosureAction.reattemptOrder(scanApiRes?.waybillNo)
                .then(() => {
                    notifyApiSuccess("Successfully Completed")
                    setScanApiRes({})
                })
                .catch((error) => {
                    console.error("Reattempt order api failed with following error", error)
                })

        }
    }

    const { Search } = Input;

    return (
        <Card className='scan-card'>
            <div className='scan-input-div-container'>
                <div className="flex-5">
                    <Search
                        placeholder="Scan Waybill"
                        value={scannedAwb}
                        onChange={e => { handleChange(e) }}
                        onPressEnter={(e) => handleAwScan((e.target.value).trim())}
                    />
                </div>
                <div className="flex-2 text-align-center">
                    Scan Waybills to perform Inward scan or proceed further
                </div>
            </div>

            {/* Scan card info */}
            {scanApiRes && Object.keys(scanApiRes).length > 0 &&
                <>
                    <div className='flex-box flex-gap-l align-items-center'>
                        {
                            scanApiRes.title
                        }
                        {scanApiRes.suspiciousReason && scanApiRes.scanStatus != 'FAILED' &&
                            <Button className='lsn-btn-primary' href={'tel:+91' + scanApiRes?.consigneeContactNumber} >Call Customer</Button>
                        }
                        {scanApiRes.actions?.includes('reject') &&
                            <Button onClick={() => handleActionClick("reject")} className='lsn-btn-error'>Cancel Order</Button>
                        }
                        {scanApiRes.actions?.includes('reattempt') &&
                            <Button onClick={() => handleActionClick("reattempt")} className='lsn-btn-success'>Reattempt</Button>
                        }
                    </div>
                    <Card className='scan-info-card'>
                        <div className='div-info-container'>

                            {scanResCardData.map((scanData) => {
                                if (scanData.checkKey || scanData.checkKey == 0) {
                                    return <div>
                                        <b>{scanData.displayText}</b>
                                        {scanData.dataKey}
                                    </div>
                                }
                            })}

                        </div>
                        <div className='flex-1'>
                            <TamperedReasonSelect
                                customerId={scanApiRes?.customerId}
                                waybillNo={scanApiRes?.waybillNo}
                            />
                        </div>
                        <div>
                            {
                                scanApiRes?.scanStatus == "FAILED" &&
                                <SuspiciousUndelAction {...scanApiRes} />
                            }
                        </div>
                    </Card>
                </>
            }
            <ScanActionRequiredPopupModal handleClose={handleCancel}
                open={showActionModal}
                qrText={actionUrl}
            />
        </Card>
    )
}

export default ScanCard;