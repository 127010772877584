import { useEffect, useState } from "react";
import { Constants } from "../../../common/utils/constants";
import MetabaseQuestion from "../../common/metabase-question/metabase.question";
import MetabaseReport from "../components/metabase.report";
import { useSelector } from "react-redux";
import { Spin } from "antd";

export default function DAFView() {

    const [ customParams, SetCustomParams ] = useState(undefined);
    const [ opsAdmin, SetOpsAdmin ] = useState(undefined);
    
    const userLocationMapping = useSelector(state => state.userLocationMappingReducer.userLocationMapping);

    useEffect(() => {

        const config = localStorage.getItem("_configrations") ? JSON.parse(atob(localStorage.getItem("_configrations"))) : {};
        const opsAdminUserIds = config?.opsAdminUserIds || [];
        const userLocationMappingExistsForUser = userLocationMapping && Object.keys(userLocationMapping)?.length > 0;
        var clusterKeys = userLocationMapping ? Object.keys(userLocationMapping) : [];
        var opsAdmin = opsAdminUserIds.indexOf(Constants.sessionUser?.id + "") > -1;

        var _customerParams = {
            ops_manager : null,
            cluster_manager : null,
            location_id : null
        }

        if (opsAdmin) {
            SetOpsAdmin(true);
            _customerParams = {};
        }
        else {
            if (userLocationMappingExistsForUser) {
                if (clusterKeys.length > 1) {
                    _customerParams.ops_manager = Constants.sessionUser?.name;
                }
                else if (clusterKeys.length = 1) {
                    _customerParams.cluster_manager = Constants.sessionUser?.name;
                }
            }
            else {
                _customerParams.location_id = Constants.userLocationId;
            }
        }

        SetCustomParams(_customerParams);
        
    }, [])
    
    return (
        <div className="white-bg">
            {
                customParams ?
                opsAdmin ?
                <MetabaseQuestion dashboardNo={350} customParams={customParams} reportName={"daf"} /> :
                <MetabaseQuestion dashboardNo={339} customParams={customParams} reportName={"daf"} /> :
                <Spin spinning={true}></Spin>
            }
        </div>
    )
    return <MetabaseQuestion dashboardNo={339} lockedParams={["location_alias"]} reportName={"daf-loc"} />
    return <MetabaseReport reportName={opsAdminUserIds.indexOf(Constants.sessionUser?.id + "") > -1 ? "daf-central" : "daily-actionable-file"}/>
}