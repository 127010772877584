import React, { useEffect, useState } from "react";
import MetabaseWidget from "../components/metabase.widget";

const PickupReportMeta = ({ }) => {

    return (
        <div className="flex-column flex-gap-l"
            style={{ padding: '32px' }}>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"Pickup Report - Location level"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1750, reportName: "pickup-report-location-level",
                        lockedParams: ["location_id"], height: "500px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"Pickup Report - User level"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1751, reportName: "pickup-report-user-level",
                        lockedParams: ["location_id"], height: "400px"
                    }}
                />
                <MetabaseWidget text={"Pickup Report"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1749, reportName: "pickup-report",
                        lockedParams: ["location_id"], height: "400px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"Pickup Report - Unassigned Waybills"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2015, reportName: "pickup-report-unassigned-waybills",
                        lockedParams: ["location_id"], height: "400px"
                    }}
                />
            </div>
        </div>
    )

}

export default PickupReportMeta;
