import React from "react";
import MetabaseWidget from "../components/metabase.widget";
import { Divider } from "antd";

export default function FirstMileMetabaseDashboard() {

    return (
        <div className="flex-column flex-gap-l"
            style={{ padding: '32px' }}>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"RTO Pendency Report"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 48, reportName: "rto-pendency-report",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"FE Level Performance"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 11, reportName: "fe-level-performance",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
                <MetabaseWidget text={"FE Seller Level Performance"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 12, reportName: "fe-seller-level-performance",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"FM Pickup Dump"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 7, reportName: "fm-pickup-dump",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
                <MetabaseWidget text={"Pickup Failed Reasons - Location level"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 77, reportName: "pickup-failed-reasons-location-level",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"FM Booking Report"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2, reportName: "fm-booking-report",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"FM Booking Report New"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 99, reportName: "fm-booking-report-new",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"FM Decoupling Handover Report"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 131, reportName: "fm-decoupling-handover-report",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"Pickup Pending Report"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 509, reportName: "pickup-pending-report",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
            </div>
            <div className="flex-box flex-gap-m justify-content-center">
                <MetabaseWidget text={"RTO-EPOD Adoption stat - Location level"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1940, reportName: "rto-epod-adoption-stat-location-level",
                        lockedParams: ["partner_id", "location_id"], height: "400px"
                    }}
                />
            </div>
        </div>
    )

}