import { Button, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import TrackingIcon from "../../assets/img/icons/4_w.svg";
import * as utils from "../../common/utils/utils";
import { generateID } from "../../common/utils/utils";
import Radio from "../ui/radio/radio";
import * as actions from "./action";
import BulkTrackingPopUp from "./bulkTrackingPopup/bulkTrackingPopup";
import TrackingListing from "./TrackingListing/TrackingListing";

const { TextArea } = Input;

const trackByValues = [
  {
    id: generateID(),
    label: "WayBill No.",
    value: "waybills",
    cardTitle: "Enter Waybill to track."
  },
  {
    id: generateID(),
    label: "Manifest No",
    value: "manifests",
    cardTitle: "Enter Manifest Number to track."
  }
];

class Tracking extends Component {
  state = {
    searchType: trackByValues[0].value,
    inputData: [],
    inputPlaceholder: "Enter Waybill Number",
    cardTitle: "Enter Waybill to track.",
    trackingSpinner: false
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.consignments &&
      this.props.consignments !== prevProps.consignments
    ) {
      if (!(this.props.bulkTrackingPopup && this.state.bulkTrackingPopup.show && this.state.bulkTrackingPopup.s3Url) && this.props.consignments.length === 0) {
        utils.notifyApiError(
          `No Tracking details found for ${this.state.inputData}`,
          "Data not found"
        );
      }
    }

    if (this.props.consignments !== prevProps.consignments) {
      this.setState({ tableDataArray: this.props.consignments })
    }
  }
  onRadioChange = event => {
    const trackingType = trackByValues.find(type => {
      return type.value === event.target.value;
    });
    const nextState = {
      searchType: event.target.value
    };
    if (trackingType) {
      nextState["inputPlaceholder"] = `Enter ${trackingType.label}`;
      nextState["cardTitle"] = trackingType.cardTitle;
      nextState["inputData"] = "";
      nextState["tableDataArray"] = []
    }
    this.setState(nextState);
  };
  onTrackClick = () => {
    if (!this.state.inputData.trim()) {
      return;
    }

    this.setState({ trackingSpinner: true })

    this.setState({ tableDataArray: [] })
    this.setState({ clearSearch: !this.state.clearSearch })

    const waybillsInput = utils.trimEachWaybill(this.state.inputData);
    if (this.state.searchType === "manifests") {
      this.props.getManifestDetails({
        manifestCodes: waybillsInput,
      }, this.stopTrackingSpinner);
    } else {
      this.props.getWaybillDetails({
        input: waybillsInput,
        searchType: this.state.searchType
      }, this.stopTrackingSpinner);
    }
  };
  onInputChange = event => {
    this.setState({
      inputData: event.target.value
    });
  };

  stopTrackingSpinner = () => {
    this.setState({ trackingSpinner: false })
  }

  render() {
    return (
      <div className="flex-column flex-gap-xl align-items-stretch">
        <div className="flex-column border-radius-s align-items-stretch flex-gap-l white-bg spacer-vl border-grey">
          <div className="font-size-l-3 text-align-center text-semi-bold">
            {this.state.cardTitle}
          </div>
          <div className="flex-box justify-content-space-around">
            <Radio values={trackByValues} onChange={this.onRadioChange}
              defaultValue={this.state.searchType} />
          </div>
          <div className="flex-box justify-content-center">
            <div className="flex-box justify-content-space-around flex-gap-l align-items-center" style={{ width: 550, marginLeft: 90 }}>
              <div className="flex-6">
                <TextArea placeholder={this.state.inputPlaceholder} autoSize={false}
                  style={{ width: "100%", maxHeight: 200 }} value={this.state.inputData} onChange={this.onInputChange} />
              </div>
              <div className="flex-1">
                <Button onClick={this.onTrackClick} className="lsn-btn-primary" loading={this.state.trackingSpinner}>
                  <img src={TrackingIcon}
                    className="track-icon" alt="track waybill" />
                  Track
                </Button>
              </div>
            </div>
          </div>
          <div className="flex-box justify-content-space-around">
            <div className="font-size-m-2 light-grey-text">
              You can track multiple values with comma ( , ) or adding next line
            </div>
          </div>
        </div>
        <TrackingListing
          tableData={this.state.tableDataArray}
          selectedRadioValue={this.state.searchType}
          inputData={this.state.inputData}
          tableDataShallowCopy={this.state.tableDataArray?.slice()}
          clearSearchText={this.state.clearSearch}
        />
        {
          this.props.bulkTrackingPopup &&
          this.props.bulkTrackingPopup.show &&
          this.props.bulkTrackingPopup.s3Url &&
          this.props.bulkTrackingPopup.treshold &&
          this.props.bulkTrackingPopup.currentCount &&
          (
            <BulkTrackingPopUp
              popUpPayload={this.props.bulkTrackingPopup}
              onClose={this.props.bulkTrackingPopUpClose}
              triggeredFor={this.state.searchType}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  consignments: state.tracking.consignments,
  loadingWaybills: state.tracking.loadingWaybills,
  bulkTrackingPopup: state.tracking.bulkTrackingPopup
});
const mapDispatchToProps = dispatch => ({
  getWaybillDetails: (params, stopTrackingSpinner) => dispatch(actions.getWaybillDetails(params, stopTrackingSpinner)),
  getWaybillTrackingDetails: params =>
    dispatch(actions.getWaybillTrackingDetails(params)),
  getManifestDetails: (payload, stopTrackingSpinner) => dispatch(actions.getManifestDetails(payload, stopTrackingSpinner)),
  bulkTrackingPopUpClose: () => dispatch(actions.bulkTrackingPopUpClose())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tracking);
