import React, { Component } from "react";
import { Button, Card, Empty, Modal, Pagination, Spin, Table } from "antd";
import { canAllow, downloadFile } from "../../../common/utils/utils";
import { permissionTags } from "../../../configs/permissionTags";
import { getPostFilterPayload } from "../../../common/utils/apiGateway";
import { columns } from "./columns.d";
import moment from "moment";
import {
  fetchFElist, fetchfitleredLeads, fetchPickupSparkline, fetchLeadAllocation,
  downloadfitleredLeads, fetchLeadsFilterData, fetchFEListV2
} from "./action";
import PickupTableMenu from "./table.menu";
import PickupSparkline from "./pickup.sparkline";
import AssignFEModal from "./assign.modal";
import "./ManagePickup.style.scss";
import "../../../../src/assets/styles/common.scss";
import { Constants } from "../../../common/utils/constants";


export default class ManagePickup extends Component {

  defaultFilterPayload = {
    pageNo: 1,
    pageSize: getPostFilterPayload().filters.pageSize,
    createdAt: {
      from: moment().subtract(7, "days").startOf("day").valueOf(),
      to: moment().endOf("day").valueOf()
    },
    customerIds: [],
    regionIds: [],
    shippers: [],
    leadStatus: [],
    waybills: [],
    pincodeIds: [],
    assetIds: []
  };

  config = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)) : localStorage._configrations;

  constructor(props) {
    super(props);
    this.state = {
      sparklineLoading: false,
      tableLoading: false,
      downloadLoading: false,
      feloading: false,
      sparklineData: {},
      filteredLeadData: {},
      leadeFilterData: {},
      selectedRowData: null,
      lastRefreshed: moment().valueOf(),
      isAssignModalOpen: false,
      assignSuccess: false,
      managePickupPermission: {
        read: canAllow(permissionTags.leads.managePickup.read),
        update: canAllow(permissionTags.leads.managePickup.update),
        create: canAllow(permissionTags.leads.managePickup.create),
        delete: canAllow(permissionTags.leads.managePickup.delete)
      },
      waybills: "",
      feUserId: undefined,
      filterPayload: { ...this.defaultFilterPayload },
      selectedRowKeys: [],
      popupLoading: false,
      unpaginatedFilteredLeadData: {},
      isFeButtonDisabled: false,
      filterNotApplied: false,
      loadPickupFilterData: true
    };
  }

  componentDidMount() {
    this.refreshEntireScreen();
  }

  refreshEntireScreen = () => {
    this.setState({
      lastRefreshed: moment().valueOf()
    }, () => {
      this.fetchAllSparklineInfo();
      // this.fetchAllLeadInfo();
      this.setState({ filterNotApplied: true })
      if (this.config?.enableManagePickupLoadFilterButton === 'true') {
        this.setState({ loadPickupFilterData: true })
      } else {
        this.fetchLeadsFilterData();
      }
    });
  };

  fetchAllSparklineInfo = () => {
    this.setState({ sparklineLoading: true }, () => {
      fetchPickupSparkline()
        .then(resp => {
          this.setState({
            sparklineLoading: false,
            sparklineData: resp?.data?.response || resp
          });
        })
        .catch(_ => this.setState({ sparklineLoading: false }));
    });
  };

  fetchAllLeadInfo = () => {
    this.setState({ tableLoading: true }, () => {
      fetchfitleredLeads({
        ...this.state.filterPayload
      }).then(resp => {
        let _leadeFilterData = { ...resp };
        _leadeFilterData.currentPageNo = resp.pageNo;
        _leadeFilterData.currentPageSize = resp.pageSize;
        _leadeFilterData.totalPincodes = resp.pincodeCount;
        _leadeFilterData.totalShippers = resp.shipperCount;
        this.setState({
          lastRefreshed: moment().valueOf(),
          tableLoading: false,
          filteredLeadData: _leadeFilterData,
          filterNotApplied: false
        });
      })
        .catch(_ => this.setState({ tableLoading: false }));
    });
  };

  fetchLeadsFilterData = () => {
    this.setState({ tableLoading: true }, () => {

      const cacheFilterResponse = localStorage.getItem("managePickupFilterResponse") ? JSON.parse(atob(localStorage.getItem("managePickupFilterResponse"))) : false;
      if (cacheFilterResponse && Constants.PARTNER_ID != "268") {
        const cacheTill = cacheFilterResponse?.cacheTill;
        const currentTime = moment().valueOf();
        var _filterPayloadCreatedAt = { ...this.state.filterPayload.createdAt };
        var _defaultFilterPayloadCreatedAt = { ...this.state.filterPayload.createdAt }
        var isDefaultTimeFilter = _filterPayloadCreatedAt.from == _defaultFilterPayloadCreatedAt.from;
        isDefaultTimeFilter = isDefaultTimeFilter && _filterPayloadCreatedAt.to == _defaultFilterPayloadCreatedAt.to;

        if (isDefaultTimeFilter && cacheTill >= currentTime) {
          return this.setState({
            leadeFilterData: cacheFilterResponse._leadeFilterData,
            filterFEList: cacheFilterResponse._leadeFilterData?.users,
            lastRefreshed: moment().valueOf(),
            tableLoading: false,
            loadPickupFilterData: false
          });
        }
      }

      fetchLeadsFilterData({
        ...this.state.filterPayload
      }).then(resp => {

        var _filterPayloadCreatedAt = { ...this.state.filterPayload.createdAt };
        var _defaultFilterPayloadCreatedAt = { ...this.state.filterPayload.createdAt }
        var isDefaultTimeFilter = _filterPayloadCreatedAt.from == _defaultFilterPayloadCreatedAt.from;
        isDefaultTimeFilter = isDefaultTimeFilter && _filterPayloadCreatedAt.to == _defaultFilterPayloadCreatedAt.to;

        if (isDefaultTimeFilter && Constants.PARTNER_ID != "268") {
          var managePickupFilterResponse = {
            cacheTill: moment().add(3, "hours").valueOf(),
            _leadeFilterData: { ...resp }
          }
          localStorage.setItem("managePickupFilterResponse", window.btoa(JSON.stringify(managePickupFilterResponse)))
        };

        this.setState({
          leadeFilterData: resp,
          filterFEList: resp?.users,
          lastRefreshed: moment().valueOf(),
          tableLoading: false,
          loadPickupFilterData: false
        });
      })
        .catch(_ => this.setState({ tableLoading: false, loadPickupFilterData: false }));
    });
  };

  downloadAllLeadInfo = () => {
    this.setState({ downloadLoading: true }, () => {
      downloadfitleredLeads({ ...this.state.filterPayload }).then(resp => {
        if (resp?.data?.response?.s3FileLink || resp?.s3FileUrl) {
          downloadFile(resp?.data?.response?.s3FileLink || resp?.s3FileUrl);
        }
        this.setState({
          downloadLoading: false
        });
      })
        .catch(_ => this.setState({ downloadLoading: false }));
    });
  };

  allocateWaybillToFE = () => {

    this.setState({ isFeButtonDisabled: true })

    const reqBody = {
      filters: { ...this.state.filterPayload },
      feUserId: this.state?.feUserId,
      lastRefreshed: this.state.lastRefreshed
    }
    reqBody.filters.pageSize = this.state.filteredLeadData.totalCount
    reqBody.filters.pageNo = 1
    delete reqBody.filters.regionIds
    delete reqBody.filters.leadStatus

    this.setState({ feloading: true }, () => {
      fetchLeadAllocation(reqBody).then(resp => {


        Modal.success({
          title: "FE assigned successfully!",
          onOk: () => {
            this.setState({
              assignSuccess: true,
              feloading: false,
              isAssignModalOpen: false,
              selectedRowData: null,
              selectedRowKeys: [],
              feUserId: undefined,
              isFeButtonDisabled: false,
            }, this.resetFilters);
          }
        });
        this.refreshEntireScreen()

      })
        .catch(err => {
          this.setState({ feloading: false })
          Modal.error({
            title: "FE assignment failed!",
            content: err?.message,
            onOk: () => {
              this.setState({
                feloading: false,
                isAssignModalOpen: false,
                isFeButtonDisabled: false,
              });
            }
          });
        });
    }
    );
  };

  fetchAllFE = () => {
    this.setState({ feloading: true }, () => {

      if (true) {
        fetchFEListV2()
          .then(resp => {
            this.setState({
              feloading: false,
              feList: resp?.userDetails
            });
          })
          .catch(_ => this.setState({ feloading: false }))
      } else {

        fetchFElist().then(resp => {
          this.setState({
            feloading: false,
            feList: resp?.data?.response?.assetAssignedUsers
          });
        })
          .catch(_ => this.setState({ feloading: false }));

      }

    });
  };

  getPaginatedData = pageNo => {
    this.setState({
      filterPayload: { ...this.state.filterPayload, pageNo }
    }, this.fetchAllLeadInfo);
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys,
      selectedRowData: selectedRowKeys?.map(
        key => (true ? this.state.filteredLeadData?.consignments : this.state.filteredLeadData?.filteredLeads)[key]
      )
    });
  };

  handleFilterChange = (key, values) => {
    var filterKey = key;
    var filterValues = Array.isArray(values) ? [...values] : values;
    if (key == "leadStatus") {
      if (values.indexOf("PPEND") > -1)
        filterValues.push("PCREATED")
      filterKey = "statuses";
    }
    this.setState({
      filterPayload: {
        ...this.state.filterPayload,
        [key]: values,
        [filterKey]: filterValues,
        pageNo: 1
      },
      selectedRowData: null,
      selectedRowKeys: [],
      filterNotApplied: true
    }, _ => {
      if (key == "waybills") {
        this.fetchAllLeadInfo();
      }
    });


    if (key == "createdAt") {
      this.fetchLeadsFilterData();
    }
  };

  resetFilters = _ => {
    this.setState({
      assignSuccess: false,
      filterPayload: { ...this.defaultFilterPayload },
      selectedRowData: null,
      selectedRowKeys: []
    }, this.fetchAllLeadInfo);
  }

  fetchUnpaginatedLeadData = () => {

    this.setState({ popupLoading: true }, () => {
      fetchfitleredLeads({
        ...this.state.filterPayload,
        pageNo: undefined,
        pageSize: undefined
      }).then(resp => {

        let _leadeFilterData = { ...resp };
        _leadeFilterData.currentPageNo = resp.pageNo;
        _leadeFilterData.currentPageSize = resp.pageSize;
        _leadeFilterData.totalPincodes = resp.pincodeCount;
        _leadeFilterData.totalShippers = resp.shipperCount;

        this.setState({
          popupLoading: false,
          unpaginatedFilteredLeadData: _leadeFilterData
        });
      })
        .catch(_ => this.setState({ tableLoading: false }));
    });
  };

  handleAssignFeModal = () => {

    this.fetchAllFE();

    this.setState({
      isAssignModalOpen: true
    });

  }

  render() {
    const {
      isAssignModalOpen,
      filteredLeadData,
      leadeFilterData,
      selectedRowKeys,
      filterPayload,
      sparklineLoading,
      sparklineData,
      tableLoading,
      lastRefreshed,
      feloading,
      feList,
      downloadLoading,
      popupLoading,
      unpaginatedFilteredLeadData,
      filterNotApplied,
      loadPickupFilterData,
      filterFEList
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelect: _ => this.setState({ selectedRowKeys })
    };

    const selectedLength = selectedRowKeys?.length ? filteredLeadData?.totalCount : 0;

    return (
      <Card className="remove-card-body-padding">
        <div className="flex-column white-bg flex-gap-m">
          <Spin spinning={sparklineLoading}>
            <PickupSparkline keyReference={{
              "unassigned": "Total Pending",
              "pendingCount": "Total Pending",
              "assignedToday": "Assigned Today",
              "outForPickupToday": "Out for Pickup",
              "outForPickup": "Out for Pickup",
              "notPickedToday": "Not Picked Today",
              "notPickedUpToday": "Not Picked Today",
              "pickedToday": "Picked Today",
              "pickedUpToday": "Picked Today",
              "cancelledToday": "Cancelled Today",
              "receivedToday": "Received Today"
            }} dataStyleObj={{
              "unassigned": { color: "#FF9801" },
              "pendingCount": { color: "#FF9801" },
              "assignedToday": { color: "#1C6CE1" },
              "notPickedToday": { color: "#F44337" },
              "notPickedUpToday": { color: "#F44337" },
              "pickedToday": { color: "#B33FB5" },
              "pickedUpToday": { color: "#B33FB5" },
              "receivedToday": { color: "#50B154" },
              "outForPickupToday": { color: "#0D87FF" },
              "outForPickup": { color: "#0D87FF" },
              "cancelledToday": { color: "#F44337" }
            }} dataObject={sparklineData} lastRefreshed={lastRefreshed} refreshLeadInfo={this.refreshEntireScreen} />
          </Spin>

          <Spin spinning={tableLoading}>
            <div className="spacer-hs">
              <fieldset className="default-fieldset">
                <legend> Pickups List </legend>

                <PickupTableMenu handleFilterChange={this.handleFilterChange} downloadLoading={downloadLoading}
                  filteredLeadData={leadeFilterData} filterPayload={filterPayload} downloadAllLeadInfo={this.downloadAllLeadInfo}
                  selectedLength={selectedLength} resetFilters={this.resetFilters} feList={filterFEList}
                  assignSuccess={this.state.assignSuccess}
                  openAssignFEModal={_ => this.handleAssignFeModal()}
                  onWaybillSearch={value => {
                    var waybillArray = [];
                    if (typeof value === "string" && value.trim() !== "") {
                      var tempVal = value + "";
                      tempVal = tempVal.split("\n").join(",");
                      tempVal = tempVal.split(" ").join(",");
                      waybillArray = tempVal.split(",");
                      waybillArray = waybillArray.map(w => w?.trim());
                    }
                    this.handleFilterChange("waybills", waybillArray);
                    this.setState({ waybills: waybillArray.join(",") });
                  }}
                  onWaybillChange={w => this.setState({ waybills: w })}
                  filterNotApplied={filterNotApplied}
                  loadPickupFilterData={loadPickupFilterData}
                  fetchLeadsFilterData={this.fetchLeadsFilterData}
                />

                <Spin spinning={filterNotApplied}
                  indicator={filterNotApplied ? <div><Button size="large" type="primary"
                    className="load-btn" onClick={this.fetchAllLeadInfo}>
                    Apply Filters </Button></div> : undefined} >
                  <div className="flex-column flex-gap-m spacer-hm">
                    <Table style={{ borderRadius: 10 }} bordered={true} columns={columns}
                      rowSelection={rowSelection} size="middle"
                      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
                      loading={filteredLeadData?.loading}
                      dataSource={true ? filteredLeadData?.consignments : filteredLeadData?.filteredLeads}
                      pagination={false} scroll={{ x: true }} />
                    <Pagination className="flex-box justify-content-flex-end" onChange={this.getPaginatedData}
                      total={!!filteredLeadData?.totalCount ? filteredLeadData?.totalCount : 0}
                      current={!!filteredLeadData?.currentPageNo ? filteredLeadData?.currentPageNo : 0}
                      pageSize={!!filteredLeadData?.currentPageSize ? filteredLeadData?.currentPageSize : 0}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} />
                  </div>
                </Spin>

                <AssignFEModal isAssignModalOpen={isAssignModalOpen}
                  popupLoading={popupLoading}
                  onAssignCancel={_ => {
                    this.setState({
                      isAssignModalOpen: false,
                      feUserId: undefined
                    });
                  }}
                  onFeChange={feUserId => this.setState({ feUserId })}
                  feUserId={this.state.feUserId} feloading={feloading}
                  feList={feList} filteredLeadData={filteredLeadData}
                  unpaginatedFilteredLeadData={unpaginatedFilteredLeadData}
                  allocateWaybillToFE={this.allocateWaybillToFE}
                  isFeButtonDisabled={this.state.isFeButtonDisabled} />
              </fieldset>
            </div>
          </Spin>
        </div>
      </Card>
    );
  }
}
