import { Button, Icon, Input, Modal, Select, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notifyApiError, notifyApiSuccess } from "../../../../common/utils/utils";
import LoaderService from '../../../../services/loader';
import { SoundNotificationService } from '../../../../services/sound.service';
import MetabaseQuestion from "../../../common/metabase-question/metabase.question";
import { destroyNotification } from "../../../ui/notification/Notification";
import ScanListInput from "../../../ui/scan-list-input";
import { fetchPaginationObject, notifyRemoveSuccess, notifyScanSuccess } from "../../../ui/scan-list/utils";
import * as connectionActions from "../service";
import "./index.scss";
import LastScanManifestBlock from "./last.scan.manifest";

const soundService = new SoundNotificationService();

export default function CreateConnectionSheet(props) {

    const listId = "manifestsForConnecitons";
    const [loading, SetLoading] = useState(false);
    const [pendingListJSON, SetPendingListJSON] = useState(false);
    const [pendingListModal, SetPendingListModal] = useState(false);
    const [updatingManifests, SetUpdatingManifests] = useState(false);
    const [pendingManifestsInfo, SetPendingManifestsInfo] = useState({});
    const [scannedManifestInfo, SetScannedManifestsInfo] = useState({});
    const [selectedDestination, SetSelectedDestination] = useState(undefined);
    const [lastScannedManifest, SetLastScannedManifest] = useState(undefined);
    const [destinationLocations, SetDestinationLocations] = useState(undefined);
    const [connectionSheetInfo, SetConnectionSheetInfo] = useState(undefined);
    const loader = useSelector(state => state.loaderReducer?.loader);
    const appConfig = useSelector(state => state?.app?.configurations)

    const metabaseQuestionJson = {
        questionNo: 2246,
        reportName: "manifests-pending-for-connection",
        lockedParams: ["location_id"]
    }

    const onEntitySelect = selectedLoc => {
        const updatedLocation = {
            id: selectedLoc?.key,
            name: selectedLoc?.label
        }
        SetSelectedDestination(updatedLocation);
    }

    // Create connection API call
    const postCreateConnectionSheet = _ => {

        if (!selectedDestination || !selectedDestination.id) return notifyApiError("Select destination location to create a connection sheet!");
        const postObj = {
            destinationLocationId: selectedDestination.id
        }
        SetLoading(true);
        if (appConfig?.enableRearch?.enableConnectionWriteOps == "true") {
            connectionActions.createConnection(postObj)
                .then(response => {
                    if (response) {
                        notifyApiSuccess("Successfully created Connection Sheet", response.connectionCode);
                        props.history.push(`/appv2/trips/form/connection-sheet/${response.connectionCode}`);
                    }
                    SetLoading(false);
                })
        }
        else {
            connectionActions.createConnectionSheet(postObj)
                .then(response => {
                    if (response && response.connection) {
                        notifyApiSuccess("Successfully created Connection Sheet", response.connection.code);
                        props.history.push(`/appv2/trips/form/connection-sheet/${response.connection.code}`);
                    }
                    SetLoading(false);
                });
        }
    }
    // Fetch destination locations API call
    const fetchDestinationLocations = _ => {
        if (!destinationLocations) {
            LoaderService.toggleLoaderOn();
            if (appConfig?.enableRearch?.enableConnectionWriteOps == "true") {
                connectionActions.fetchDestinationLocations()
                    .then(resp => {
                        SetDestinationLocations(resp)
                        LoaderService.toggleLoaderOff();
                    })
                    .catch(_ => LoaderService.toggleLoaderOff());
            }
            else {
                connectionActions.fetchConnectionDestinationsOptions()
                    .then(locations => {
                        SetDestinationLocations(locations)
                        LoaderService.toggleLoaderOff();
                    })
                    .catch(_ => LoaderService.toggleLoaderOff());
            }
        }
    }
    // Fetch connection details by connection code API
    useEffect(_ => {
        if (props?.match?.params?.connectionCode) {
            LoaderService.toggleLoaderOn();
            if (appConfig?.enableRearch?.enableConnectionReadOps == "true") {
                connectionActions.fetchConnectionDetailsByCode(props?.match?.params?.connectionCode)
                    .then(async resp => {
                        SetSelectedDestination({ id: resp.destinationLocation });
                        SetConnectionSheetInfo(resp);
                        await Promise.all([getPaginationPendingManifest(), getPaginationScannedManifest()]);
                        LoaderService.toggleLoaderOff();
                    })
                    .catch(_ => {
                        LoaderService.toggleLoaderOff();
                        notifyApiError("Connection not found!");
                        props.history.goBack();
                    });
            }
            else {
                connectionActions.fetchConnectionByCode(props?.match?.params?.connectionCode)
                    .then(async response => {
                        SetSelectedDestination({ id: response.destinationLocation });
                        SetConnectionSheetInfo(response);
                        await Promise.all([getPaginationPendingManifest(), getPaginationScannedManifest()]);
                        LoaderService.toggleLoaderOff();
                    })
                    .catch(_ => {
                        LoaderService.toggleLoaderOff();
                        notifyApiError("Connection not found!");
                        props.history.goBack();
                    });
            }
        }
    }, [props?.match?.params?.connectionCode])

    // Fetch unassigned manifests API
    const getPaginationPendingManifest = params => new Promise((resolve, reject) => {
        SetUpdatingManifests(true);
        if (appConfig?.enableRearch?.enableConnectionReadOps == "true") {

            const payload = {
                pageNo: params && params.pageNo ? params.pageNo : 1,
                pageSize: params && params.pageSize ? params.pageSize : 10,
                connectionCode: props?.match?.params?.connectionCode,
                manifestStatus: ["PENDING","SHORTAGE"]
            }

            if (params?.manifestCode) {
                payload['manifestCode'] = params.manifestCode
            }

            connectionActions.fetchManifestList(payload)
                .then((res) => {
                    res['manifests'] = [...res.manifestData]
                    SetPendingManifestsInfo(res)
                    SetUpdatingManifests(false);
                })
                .catch(err => {
                    SetUpdatingManifests(false);
                    console.error(err)
                })

        }
        else {
            connectionActions.fetchPendingManifest(props?.match?.params?.connectionCode, params)
                .then(response => {
                    if (response.currentPageNo > response.maxPage) {
                        getPaginationPendingManifest({ pageNo: response.maxPage })
                            .then(pendingManifests => {
                                SetPendingManifestsInfo(pendingManifests);
                                resolve(pendingManifests);
                            })
                    }
                    else {
                        SetPendingManifestsInfo(response);
                        resolve(response)
                    }
                    SetUpdatingManifests(false);
                })
                .catch(exp => {
                    SetUpdatingManifests(false);
                    reject(exp);
                })
        }

    })
    // Fetch assigned manifests API
    const getPaginationScannedManifest = params => new Promise((resolve, reject) => {
        SetUpdatingManifests(true);

        if (appConfig?.enableRearch?.enableConnectionReadOps == "true") {

            const payload = {
                pageNo: params && params.pageNo ? params.pageNo : 1,
                pageSize: params && params.pageSize ? params.pageSize : 10,
                connectionCode: props?.match?.params?.connectionCode,
                manifestStatus: ["LINKED"]
            }

            if (params?.manifestCode) {
                payload['manifestCode'] = params.manifestCode
            }

            connectionActions.fetchManifestList(payload)
                .then((res) => {
                    res['manifests'] = [...res.manifestData]
                    SetScannedManifestsInfo(res)
                    SetUpdatingManifests(false);
                })
                .catch(err => {
                    SetUpdatingManifests(false);
                    console.error(err)
                })

        }
        else {
            connectionActions.fetchScannedManifest(props?.match?.params?.connectionCode, params)
                .then(response => {
                    if (response.currentPageNo > response.maxPage) {
                        getPaginationScannedManifest({ pageNo: response.maxPage })
                            .then(scannedManifests => {
                                SetScannedManifestsInfo(scannedManifests);
                                resolve(scannedManifests);
                            })
                    }
                    else {
                        SetScannedManifestsInfo(response);
                        resolve(response)
                    }
                    SetUpdatingManifests(false);
                })
                .catch(exp => {
                    SetUpdatingManifests(false);
                    reject(exp);
                })
        }
    })

    const columns = [
        {
            title: "Manifest ID",
            dataIndex: "code"
        },
        {
            title: "Items Count",
            dataIndex: "quantity"
        }
    ]

    const updateLists = async _ => {
        await Promise.all([

            getPaginationPendingManifest(
                { pageNo: pendingManifestsInfo?.manifestData?.length == 1 && pendingManifestsInfo?.currentPageNo != 1 ? pendingManifestsInfo.currentPageNo - 1 : pendingManifestsInfo.currentPageNo }
            ),

            getPaginationScannedManifest(
                { pageNo: scannedManifestInfo?.manifestData?.length == 1 && scannedManifestInfo?.currentPageNo != 1 ? scannedManifestInfo.currentPageNo - 1 : scannedManifestInfo.currentPageNo }
            )
        ])
        SetUpdatingManifests(false);
    }

    // Link Delink manifest APIs
    const callManifestToConection = (data, action) => new Promise((resolve, reject) => {
        if ((typeof data === "string" && data.trim() === "") || typeof data === "undefined") {
            soundService.playGeneralWarning();
            return notifyApiError("Manifest ID is mandatory!");
        }
        SetUpdatingManifests(true);
        destroyNotification();
        if (appConfig?.enableRearch?.enableConnectionWriteOps == "true") {
            if (action === "link") {
                connectionActions.linkDelinkManifest({ ...(typeof data === "string" ? { code: data } : data), connectionCode: connectionSheetInfo?.connectionCode }, action)
                    .then(response => {
                        notifyScanSuccess("manifest");
                        SetLastScannedManifest(response);
                        resolve(response);
                    })
                    .catch(exp => {
                        soundService.playWarning();
                        SetUpdatingManifests(false);
                        reject(exp);
                    })
            }
            else {
                connectionActions.linkDelinkManifest({ ...(typeof data === "string" ? { code: data } : data), connectionCode: connectionSheetInfo?.connectionCode }, action)
                    .then(response => {
                        notifyRemoveSuccess("manifest");
                        resolve(response);
                    })
                    .catch(exp => {
                        soundService.playWarning();
                        SetUpdatingManifests(false);
                        reject(exp);
                    })
            }
        }
        else {
            connectionActions.manifestToConnection({ ...(typeof data === "string" ? { code: data } : data), connectionCode: connectionSheetInfo?.connectionCode }, action)
                .then(response => {
                    if (action === "link") {
                        notifyScanSuccess("manifest");
                        SetLastScannedManifest(response.manifest);
                    }
                    else {
                        notifyRemoveSuccess("manifest");
                    }
                    resolve(response);
                })
                .catch(exp => {
                    soundService.playWarning();
                    SetUpdatingManifests(false);
                    reject(exp);
                })
        }
    })

    const linkManifest = data => callManifestToConection(data, "link")

    const delinkManifest = data => callManifestToConection(data, "delink")

    const closePendingListModal = _ => {
        SetPendingListModal(false);
        SetPendingListJSON(false);
    }

    return (
        <Spin spinning={loader}>
            <div className="flex-column flex-gap-l">
                <div className="white-bg border-grey border-radius-s flex-column flex-gap-xl spacer-m create-connection-header">
                    <div className="flex-box justify-content-space-between align-items-center">
                        <div className="flex-box align-items-center flex-gap-m">
                            <Icon type="arrow-left" onClick={_ => props.history.push("/appv2/trips/dashboard/connection-sheet")} />
                            <div className="font-size-l-1 text-bold"> Create Connection Sheet </div>
                        </div>
                        {
                            (connectionSheetInfo && connectionSheetInfo?.connectionCode) &&
                            <div className="flex-box flex-gap-m align-items-center">
                                <Button onClick={_ => props.history.push("/appv2/trips/dashboard/connection-sheet")} className="lsn-btn-secondary">
                                    Cancel
                                </Button>
                                <Button type="primary" className="lsn-btn-primary" onClick={_ => {
                                    notifyApiSuccess("Connection updated successfully", connectionSheetInfo?.connectionCode);
                                    props.history.push("/appv2/trips/dashboard/connection-sheet")
                                }}>
                                    Update Connection
                                </Button>
                            </div>
                        }
                    </div>
                    <div className="flex-box flex-gap-l">
                        <div className="flex-1 flex-box align-items-center">
                            <div className="flex-1"> Destination </div>
                            <Select onSelect={onEntitySelect} disabled={connectionSheetInfo?.connectionCode ? true : false} className="flex-4"
                                value={selectedDestination ? { key: selectedDestination.id, label: selectedDestination.name } : undefined}
                                labelInValue={true} placeholder={"Select Destination Location"} showSearch={true}
                                onDropdownVisibleChange={visible => visible ? fetchDestinationLocations() : undefined}
                                loading={loader}
                                filterOption={(input, option) => option?.props?.children?.toLowerCase ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true}
                            >
                                {
                                    !destinationLocations ?
                                        <Select.Option disabled={true} key={-1} value={-1} >
                                            <div className="flex-box align-items-center flex-gap-l">
                                                <Spin spinning={true} />
                                                <div>Fetching Destinations</div>
                                            </div>
                                        </Select.Option> :
                                        destinationLocations?.length < 1 ?
                                            <Select.Option disabled={true} key={-1} value={-1} >
                                                No Bags to connect
                                            </Select.Option> :
                                            destinationLocations?.length > 0 &&
                                            destinationLocations.map(destination => {
                                                return (
                                                    <Select.Option value={destination.id} key={destination.id}>
                                                        {destination.name}
                                                    </Select.Option>
                                                )
                                            })

                                }
                            </Select>
                        </div>
                        <div className="flex-1">
                            {
                                (connectionSheetInfo && connectionSheetInfo?.connectionCode) ?
                                    <div className="flex-1 flex-box align-items-center" >
                                        <div className="flex-1"> Connection ID </div>
                                        <Input className="flex-4" disabled={true} value={connectionSheetInfo?.connectionCode} />
                                    </div> :
                                    <Tooltip title={!selectedDestination ? "Select destination location to create a connection sheet!" : undefined}>
                                        <Button loading={loading} onClick={postCreateConnectionSheet} type="primary" className="lsn-btn-primary">
                                            Create Connection Sheet
                                        </Button>
                                    </Tooltip>
                            }
                        </div>
                    </div>
                </div>
                {
                    (connectionSheetInfo && connectionSheetInfo?.connectionCode) ?
                        pendingManifestsInfo.manifests !== undefined ?
                            <Spin spinning={updatingManifests}>
                                <ScanListInput listId={listId} showCount={true} entityType={"Manifest"} enableSound={true} loading={updatingManifests}

                                    pendingColumns={[...columns]}
                                    pendingList={pendingManifestsInfo.manifests}
                                    pendingCount={pendingManifestsInfo?.totalCount}
                                    pendingPagination={fetchPaginationObject(pendingManifestsInfo, pageNo => getPaginationPendingManifest({ pageNo: pageNo }), "Manifest")}
                                    beforeAdd={linkManifest}
                                    pendingTableProps={{ scroll: { y: 120 } }}
                                    enablePendingSearch={true}
                                    onPendingSearch={searchText => getPaginationPendingManifest({ manifestCode: searchText })}
                                    onAdd={updateLists}
                                    skipAdd={true}
                                    viewPendingList={true}
                                    onViewPendingList={_ => {
                                        if (connectionSheetInfo?.connectionCode && selectedDestination?.id) {
                                            SetPendingListModal(true);
                                            SetPendingListJSON({
                                                ...metabaseQuestionJson,
                                                customParams: {
                                                    destination_location_id: selectedDestination?.id
                                                }
                                            });
                                        }
                                        else if (pendingManifestsInfo?.totalCount == 0) {
                                            Modal.confirm({
                                                title: "No pending manifest",
                                                cancelButtonProps: {
                                                    className: "display-none"
                                                }
                                            })
                                        }
                                    }}

                                    scannedColumns={[...columns]}
                                    scannedList={scannedManifestInfo.manifests}
                                    scannedCount={scannedManifestInfo?.totalCount}
                                    scannedPagination={fetchPaginationObject(scannedManifestInfo, pageNo => getPaginationScannedManifest({ pageNo: pageNo }), "Manifest")}
                                    beforeRemove={delinkManifest}
                                    scannedTableProps={{ scroll: { y: 120 } }}
                                    enableScannedSearch={true}
                                    onScannedSearch={searchText => getPaginationScannedManifest({ manifestCode: searchText })}
                                    onRemove={updateLists}
                                    skipRemove={true}

                                    onInputScan={linkManifest}
                                    inputHeader={"Scan Manifests"}
                                    inputPlaceholder={"Enter Manifest ID to scan"}
                                    lastScanBlock={LastScanManifestBlock({ lastScannedManifest: lastScannedManifest })} />
                            </Spin> :
                            <Spin spinning={true} /> :
                        <></>
                }
            </div>
            {
                pendingListModal &&
                <Modal width={"75vw"} title="Pending Manifest List" centered={true} visible={pendingListModal}
                    cancelButtonProps={{ className: "display-none" }} cancelText="Close"
                    onOk={closePendingListModal} onCancel={closePendingListModal}>
                    <MetabaseQuestion {...pendingListJSON} />
                </Modal>
            }
        </Spin>
    )
}