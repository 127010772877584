import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import bwipjs from 'bwip-js';

const ScanActionRequiredVideoPopupModal = ({ open, handleClose}) => {

  const videoId = 'MCLx-dAImPc'; // Replace with your desired YouTube video ID
  const url = `https://www.youtube.com/embed/${videoId}`;
  return (
    <Modal className="remove-padding" title={
        <div style={{ display: 'flex', justifyContent: 'left' }}> 
          <div>How to use BRANCH app?</div> 
        </div>
      }
      onCancel={handleClose} visible={open}
      width="40vw" footer={false} centered={true}
      destroyOnClose={true} >
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20}}>
        
        <div style={{ display: 'flex', justifyContent: 'center', margin: 20}}> 
            <iframe
              width="400"
              height="240"
              src={url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >

             </iframe>

             <div style={{ marginLeft: 14, width:"120px"}}><b>Follow steps as shown in the video tutorial to proceed with BRANCH app</b></div>
        </div>
       
        </div>
      
    </Modal>
  )
}

export default ScanActionRequiredVideoPopupModal;