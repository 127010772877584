import React from "react";
import MetabaseWidget from "../components/metabase.widget";
import { metabaseRedirection } from "../../../common/utils/utils";

const ScOverviewForward = () => {

    return (
        <>
            <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

                <div className="font-size-l-3 grey-text">Sorting Centre - Overview</div>

                <div className="flex-column flex-gap-xl">
                    <div className="flex-box flex-gap-m justify-content-center">
                        <MetabaseWidget text={"SC -- Number of Trips Expected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4341, reportName: "sc-number-of-trips-expected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/4479-data-dump-for-sc-number-of-trips-expected-forward#question")
                            }
                            overlayCssClassname={"metabase-widget-v1"}
                        />
                        <MetabaseWidget text={"SC -- Number of Manifest Expected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4342, reportName: "sc-number-of-manifest-expected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/4481-data-dump-for-sc-number-of-manifest-expected-forward#question")
                            }
                            overlayCssClassname={"metabase-widget-v1"}
                        />
                        <MetabaseWidget text={"SC -- Number of Consignments Expected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4343, reportName: "sc-number-of-consignments-expected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            overlayCssClassname={"metabase-widget-v1"}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/4494-data-dump-for-sc-count-of-consignments-expected-forward#question")
                            }
                        />
                        <MetabaseWidget text={"SC -- Vehicle Arrived, shipments not Received"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4344, reportName: "sc-vehicle-arrived-shipments-not-received",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            overlayCssClassname={"metabase-widget-v1"}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2657-data-dump-for-sc-vehicle-arrived-shipments-not-received-forward#question")
                            }
                        />
                    </div>
                    <div className="flex-box flex-gap-m justify-content-center">
                        <MetabaseWidget text={"SC -- Bag Received, shipments not Received"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4345, reportName: "sc-bag-received-shipments-not-received",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            overlayCssClassname={"metabase-widget-v1"}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2658-data-dump-for-sc-bag-received-shipments-not-received-forward#question")
                            }
                        />
                        <MetabaseWidget text={"SC -- Shipments Inscanned not Bagged"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4346, reportName: "sc-shipments-inscanned-not-bagged",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            overlayCssClassname={"metabase-widget-v1"}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2659-data-dump-for-sc-shipments-inscanned-not-bagged-forward#question")
                            }
                        />
                        <MetabaseWidget text={"SC -- Count of Bags not Connected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4347, reportName: "sc-count-of-bags-not-connected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            overlayCssClassname={"metabase-widget-v1"}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2661-data-dump-for-sc-count-of-bags-not-connected-forward#question")
                            }
                        />
                        <MetabaseWidget text={"SC -- Count of Shipments Bagged not Connected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4348, reportName: "sc-count-of-shipments-bagged-not-connected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            overlayCssClassname={"metabase-widget-v1"}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2662-data-dump-for-sc-count-of-shipments-bagged-not-connected-forward?#question")
                            }
                        />
                    </div>
                </div>

            </div>
        </>
    )
}

export default ScOverviewForward;