import React from "react";
import MetabaseWidget from "../components/metabase.widget";
import { metabaseRedirection } from "../../../common/utils/utils";

const ScOverviewRto = () => {

    return (
        <>
            <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>
                <div className="font-size-l-3 grey-text">Sorting Centre - Overview</div>

                <div className="flex-column flex-gap-xl">
                    <div className="flex-box flex-gap-m justify-content-center">
                        <MetabaseWidget text={"SC RTO -- Number of Trips Expected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4349, reportName: "sc-rto-number-of-trips-expected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/4495-data-dump-for-sc-rto-number-of-trips-expected#question")
                            }
                        />
                        <MetabaseWidget text={"SC RTO -- Number of Manifest Expected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4350, reportName: "sc-rto-number-of-manifest-expected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/4496-data-dump-for-sc-rto-number-of-manifest-expected-rto#question")
                            }
                        />
                        <MetabaseWidget text={"SC RTO -- Count of Consignments Expected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4351, reportName: "sc-rto-count-of-consignments-expected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/4493-data-dump-for-sc-rto-count-of-consignments-expected-rto#question")
                            }
                        />
                        <MetabaseWidget text={"SC RTO -- Vehicle Arrived, shipments not Received "}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4352, reportName: "sc-rto-vehicle-arrived-shipments-not-received",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2668-data-dump-for-sc-rto-vehicle-arrived-shipments-not-received-rto#question")
                            }
                        />
                    </div>
                    <div className="flex-box flex-gap-m justify-content-center">
                        <MetabaseWidget text={"SC RTO -- Bag received, shipments not received"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4353, reportName: "sc-rto-bag-received-shipments-not-received",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2669-data-dump-for-sc-rto-bag-received-shipments-not-received-rto#question")
                            }
                        />
                        <MetabaseWidget text={"SC RTO -- Shipments Inscanned, not Bagged"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4354, reportName: "sc-rto-shipments-inscanned-not-bagged",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2670-data-dump-for-sc-rto-shipments-inscanned-not-bagged-rto#question")
                            }
                        />
                        <MetabaseWidget text={"SC RTO -- Count of Bags not Connected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4355, reportName: "sc-rto-count-of-bags-not-connected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2671-data-dump-for-sc-rto-count-of-bags-not-connected-rto#question")
                            }
                        />
                        <MetabaseWidget text={"SC RTO -- Count of Shipments Bagged not Connected"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4356, reportName: "sc-rto-count-of-shipments-bagged-not-connected",
                                lockedParams: ["location_id"], height: "300px"
                            }}
                            onClickFuncAfterLoad={
                                () => metabaseRedirection("appv2/reports/2672-data-dump-for-sc-rto-count-of-shipments-bagged-not-connected-rto#question")
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    )

}

export default ScOverviewRto;