import { Radio } from "antd";
import RadioGroup from "antd/lib/radio/group";
import { useState } from "react";
import MetabaseReport from "../components/metabase.report";

export default function FMRTODashboard() {

    const subTabs = [
        {
            label : "Expectancy",
            reportName : "fm-rto-expectancy-dashboard"
        },
        {
            label : "Pendency",
            reportName : "fm-rto-pendency-dashboard"
        },
        {
            label : "Hub Monitoring",
            reportName : "fm-rto-monitoring-dashboard"
        }
    ];

    const [selectedTab, SetSelectedTab] = useState("fm-rto-expectancy-dashboard");

    const onTabChange = key => {
        SetSelectedTab(key);
    }

    return (
        <div className="flex-column flex-gap-m align-items-stretch" style={{ marginBottom : 15 }}>
            <RadioGroup buttonStyle="solid" onChange={event => onTabChange(event?.target?.value)} value={selectedTab} >
                {
                    subTabs.map(t => <Radio.Button className="report-preview-radio" value={t.reportName}> {t.label} </Radio.Button> )
                }
            </RadioGroup>
            <MetabaseReport reportName={selectedTab} hideBackButton={true} autoRefresh={true} autoRefreshTimetout={300} />
        </div>
    )
}