import { useState } from "react";
import MetabaseQuestion from "../../common/metabase-question/metabase.question";
import { Alert } from "antd";

export default function MetabaseWidget({ icon, text, reportJson, className,
    onClickFuncAfterLoad }) {

    const [state, setState] = useState({
        showIframe: false
    });

    return (
        reportJson ?
            <>
                {
                    state.showIframe ?
                        <div className={`border-radius-m  ${className}`}>
                            <MetabaseQuestion {...reportJson} wrapperWidth={"100%"}
                                onClickFuncAfterLoad={onClickFuncAfterLoad} />
                        </div> :
                        <div onClick={_ => {
                            setState(c => ({
                                ...c,
                                showIframe: true
                            }));
                        }} className={`font-size-l-1 cursor-pointer spacer-m flex-box align-items-center white-bg border-radius-m ${className}`}
                            style={{ height: { ...reportJson }.height, minHeight: { ...reportJson }.height }} >
                            {icon && <icon />}
                            {text &&
                                <div className="flex-column align-items-center justify-content-center">
                                    <a target='_blank' className="text-semi-bold text-align-center"> {text} </a>
                                    <div className="font-size-sm-1 grey-text">(Click to View)</div>
                                </div>
                            }
                        </div>
                }
            </> :
            <div className="flex-box white-bg border-radius-m">
                <Alert message={"Something went wrong!"} icon={"warning"} type="warning" />
            </div>
    )

}