import React, { useEffect, useRef, useState } from "react";
import { Divider, Icon, Spin, Button } from "antd";
import { createAssignedPickup, fetchPickupUploadList, createPickup } from "./actions";
import { UPLOAD_BUTTON_TYPE } from "../../common/utils/constants";
import FormWrapper from "../ui/formComponent/form";
import schemaTemplate from "./form.schema";
import { notifyApiError, notifyApiSuccess } from "../../common/utils/utils";

import { UploadListTable } from "./uploadListTable";
import { useSelector } from "react-redux";
// import tableListData from "../../tableResponse.json";

var intervalId = undefined;

const UploadAssignedPickup = () => {

    const [loading, SetLoading] = useState(false);
    const [formData, SetFormData] = useState({});
    const [allSuccess, SetAllSuccess] = useState(undefined);
    const [formSchema, SetFormSchema] = useState(undefined);
    const [pickupUploadList, setPickupUploadList] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState(null);

    const sampleFileLinks = ["https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/UPLOAD_LEAD_ASSIGN_SHIPPER_REQUEST.xlsx",
        "https://loadshare-v2.s3.ap-south-1.amazonaws.com/documents/templates/xlsx/UPLOAD_LEAD_ASSIGN_REQUEST.xlsx"]
    const sampleFileTexts = ["Download Seller level Assignment Sample File", "Download Shipment level Assignment Sample File"]

    const errorMessageText = {
        SHIPPER: "seller",
        CONSIGNMENT: "consignment"
    }

    const config = useSelector((state) => state.app.configurations)

    var formRef = useRef();

    useEffect(() => {
        schemaTemplate.schema.dependencies.uploadType.oneOf.map((upload, index) => {
            upload.properties.s3path = {
                ...upload.properties.s3path,
                downloadSampleLink: sampleFileLinks[index],
                downloadSampleText: sampleFileTexts[index],
                fileUploadProps: {
                    path: "assign-pickup",
                    objKey: "assign-pickup",
                    showLoader: true,
                    acceptTypes: [".xlsx", ".xls"],
                    directUpload: true,
                    fileUploadType: UPLOAD_BUTTON_TYPE.BUTTON,
                    showUploadList: false,
                    doNotAllowMultiple: true,
                    uploadButtonHtml: (
                        <Button>
                            <Icon type="upload" /> Upload Waybill File - .xlsx or .xls format
                        </Button>
                    ),
                    disableUploadButton: true,
                    fileSizeLimit: 1 // in MB
                },
                onUploadFinish: (path, fileName) => {
                    setUploadedFileName(fileName)
                }
            }
        })
        SetFormSchema(schemaTemplate);
    }, []);

    useEffect(() => {
            triggerPickupUploadList()

            intervalId = setInterval(() => {
                triggerPickupUploadList()
            }, 10000)

            return _ => {
                clearInterval(intervalId)
            }
    }, [refreshData])

    const triggerPickupUploadList = () => {
        SetLoading(true)
        fetchPickupUploadList()
            .then((data) => {
                if (Array.isArray(data?.list) && data.list.length > 0) {
                    setPickupUploadList(data.list)
                    if (data.list.length == 0 ||
                        !data.list.some(uploadList => (uploadList.status == "PROCESSING" || uploadList.status == "IN_QUEUE"))) {
                        clearInterval(intervalId)
                    }
                }
            })
            .catch((error) => {
                notifyApiError(JSON.stringify(error))
                console.error("Following error occurred when calling the fetchPickupUploadList api",
                    error)
            })
        SetLoading(false)
    }

    const onCreatePickupComplete = _ => {
        const tempSchema = { ...formSchema };
        SetFormSchema(undefined);
        SetFormData(current => {
            delete current.s3path;
            return current;
        });
        setTimeout(() => {
            SetFormSchema(tempSchema);
            SetLoading(false);
        }, 100);
    }

    const handleCreatePickup = _ => {
        SetLoading(true);
            const reqBody = {
                s3FileUrl: formData.s3path,
                type: formData.uploadType,
                uploadfileName: uploadedFileName
            }

            createPickup(reqBody)
                .then((data) => {
                    notifyApiSuccess("Submitted successfully")
                    setRefreshData(!refreshData)
                })
                .catch((errors) => {
                    notifyApiError(errors.error)
                    console.error("createPickup api failed with following error -> ",
                        errors)
                })
            onCreatePickupComplete();
    };

    return (
        <Spin spinning={loading || !formSchema}>
            <div className="white-bg border-radius-s border-grey spacer-l flex-column flex-gap-l">
                <div className="font-size-l-2 text-bold">Upload Assigned Pickup</div>
                <Divider style={{
                    margin: 0,
                    width: "calc(100% + 50px)",
                    left: -25
                }} />
                <div className="flex-column spacer-m flex-gap-l">
                    {
                        formSchema &&
                        <FormWrapper {...formSchema} formData={formData} hideSubmit={true}
                            onChange={SetFormData} onSubmit={handleCreatePickup} ref={formRef} />
                    }
                    <div className="flex-box flex-gap-l align-items-center">
                        <Button disabled={!formData?.s3path} className="lsn-btn-primary" type="primary" onClick={_ => {
                            formRef.current?.formReference?.current?.submit()
                        }}>
                            Create Pickup
                        </Button>
                        {
                            allSuccess ?
                                <div className="spacer-s grey-bg flex-box flex-gap-m border-grey border-radius-m align-items-center">
                                    <Icon type="info-circle" />
                                    <div>File Has been processed!</div>
                                    <div className={allSuccess?.status === false ? "error-color" : allSuccess?.status === true ? "success-color" : undefined}>
                                        {
                                            allSuccess?.status === false ?
                                                <>Some {errorMessageText[allSuccess?.uploadType]} assignments have errors. Please check the response file </> :
                                                allSuccess?.status === true ?
                                                    <>FE(s) assigned successfully for {errorMessageText[allSuccess?.uploadType]}(s)</> :
                                                    <></>
                                        }
                                    </div>
                                </div> :
                                <></>
                        }
                    </div>
                </div>
                {true && pickupUploadList.length > 0 &&
                    <div>
                        <UploadListTable tableData={pickupUploadList} handleRefreshList={() => { setRefreshData(!refreshData) }} />
                    </div>
                }
            </div>
        </Spin>
    )
};

export default UploadAssignedPickup;