import * as CONSTANTS from './constants';
import * as apiUtils from '../../common/utils/apiGateway';
import { Constants } from '../../common/utils/constants';
import * as trackingService from './trackingService';
import { isEmpty } from '../../common/utils/utils';

export const getWaybillDetails = (param, stopTrackingSpinner) => dispatch => {
  dispatch(getWaybillDetailsBegin());
  let data;
  data = {
    [param.searchType]: param.input
  }
  apiUtils.apiRequest({
    method: 'post',
    url: '/b2b/v1/tracking',
    data
  })
    .then(response => {
      const sub_response = response.data.response;
      const { trackingReportS3Link = "", maxThresholdCount = undefined, waybillCount = undefined, trackingReportTitle = "" } = sub_response;
      if (trackingReportS3Link && (waybillCount > maxThresholdCount)) {
        const bulkTrackingPopUpPayload = {
          show: true,
          s3Url: trackingReportS3Link,
          currentCount: waybillCount,
          treshold: maxThresholdCount,
          title: trackingReportTitle
        };
        dispatch(bulkTrackingPopUpOpen(bulkTrackingPopUpPayload));
      } else {
        dispatch(getWaybillDetailsSuccess(response.data.response.consignments))
      }

      if (stopTrackingSpinner) {
        stopTrackingSpinner()
      }
    })
    .catch(error => {
      dispatch(getWaybillDetailsFailure(error))
      if (stopTrackingSpinner) {
        stopTrackingSpinner()
      }
    });
}

function getWaybillDetailsBegin() {
  return {
    type: CONSTANTS.GET_WAYBILL_DETAILS_BEGIN,
  }
}

function getWaybillDetailsSuccess(response) {
  return {
    type: CONSTANTS.GET_WAYBILL_DETAILS_SUCCESS,
    payload: response,
  }
}
function getWaybillDetailsFailure() {
  return {
    type: CONSTANTS.GET_WAYBILL_DETAILS_FAILURE,
  }
}

const bulkTrackingPopUpOpen = (payload) => {
  return {
    type: CONSTANTS.BULK_TRACKING_POPUP_OPEN,
    payload
  };
}

export const bulkTrackingPopUpClose = () => {
  return {
    type: CONSTANTS.BULK_TRACKING_POPUP_CLOSE,
  }
}

export const getManifestDetails = (payload, stopTrackingSpinner) => dispatch => {
  dispatch(getWaybillDetailsBegin());

  apiUtils.apiRequest({
    method: "post",
    url: "/b2b/v1/tracking/manifest",
    data: payload
  }).then(response => {
    const sub_response = response.data.response;
    const { trackingReportS3Link = "", maxThresholdCount = undefined, manifestCount = undefined, trackingReportTitle = "" } = sub_response;
    if (trackingReportS3Link && (manifestCount > maxThresholdCount)) {
      const bulkTrackingPopUpPayload = {
        show: true,
        s3Url: trackingReportS3Link,
        currentCount: manifestCount,
        treshold: maxThresholdCount,
        title: trackingReportTitle
      };
      dispatch(bulkTrackingPopUpOpen(bulkTrackingPopUpPayload));
    } else {
      dispatch(getWaybillDetailsSuccess(response.data.response.manifestTrackings))
    }
    if (stopTrackingSpinner) {
      stopTrackingSpinner()
    }
  }).catch(err => {
    dispatch(getWaybillDetailsFailure(err))
    if (stopTrackingSpinner) {
      stopTrackingSpinner()
    }
  });
};

export const getManifestEvents = (manifestNo) => dispatch => {
  dispatch(getManifestEventsBegin());

  apiUtils.apiRequest({
    method: "GET",
    url: `b2b/v1/tracking/manifest/${manifestNo}`,
  }).then(response => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(response?.data?.status?.code) > -1) {
      dispatch(getManifestEventsSuccess(response.data.response.manifestTrackingEvents))
    } else {
      dispatch(getManifestEventsFailure(response?.data?.status?.message));
    }
  }).catch(err => {
    dispatch(getManifestEventsFailure(err));
  })

};

function getManifestEventsBegin() {
  return {
    type: CONSTANTS.GET_MANIFESTS_EVENTS_BEGIN
  };
};

function getManifestEventsSuccess(payload) {
  return {
    type: CONSTANTS.GET_MANIFESTS_EVENTS_SUCCESS,
    payload
  };
};

function getManifestEventsFailure(err) {
  return {
    type: CONSTANTS.GET_MANIFESTS_EVENTS_FAILURE,
    payload: err
  };
};

export const resetWaybillDetails = () => {
  return dispatch => {
    dispatch({
      type: CONSTANTS.WAYBILL_DETAILS_RESET,
    })
  }
}

export const getWaybillTrackingDetails = (waybillNo, source) => {
  return dispatch => {
    dispatch(getWaybillTrackingDetailsBegin());
    apiUtils.apiRequest({
      url: `/b2b/v1/tracking/${waybillNo}`,
    })
      .then(response => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(response?.data?.status?.code) > -1) {
          dispatch(getWaybillTrackingDetailsSuccess(response.data.response, source))
        } else {
          dispatch(getWaybillTrackingDetailsFailure(response?.data?.status?.message));
        }
      })
      .catch(error => {
        return dispatch(getWaybillTrackingDetailsFailure(error))
      });
  }
}

function getWaybillTrackingDetailsBegin() {
  return {
    type: CONSTANTS.GET_WAYBILL_TRACKING_DETAILS_BEGIN,
  }
}

function getWaybillTrackingDetailsSuccess(response, source) {

  return {
    type: CONSTANTS.GET_WAYBILL_TRACKING_DETAILS_SUCCESS,
    payload: response,
    source: source ? source : null
  }
}

function getWaybillTrackingDetailsFailure(error) {
  return {
    type: CONSTANTS.GET_WAYBILL_TRACKING_DETAILS_FAILURE,
    payload: error
  }
}


export function getTrackingData(data) {
  return function (dispatch) {
    return trackingService.getTrackingValues(data).then((response) => {
      dispatch(vehicleTrackingData(response.data.response));
    })
  };
}

export function setTrackingTypeData(data) {
  return function (dispatch) {
    dispatch(vehicleTrackingTypeData(data));
  };
}

export function getBeeconTelematicTrackingData(status, partnerId, mount) {
  return function (dispatch) {
    if (status && status == 'ONTRIP') {
      dispatch(vehicleSimTrackingOnTripDataBegin(null));
      return trackingService.getOnTripTrackingValues(partnerId).then((response) => {
        let teleTrackingData = {};
        let payloadData = {};
        if (response != undefined && response.data != undefined && response.data.response != undefined && response.data.response.vehicleTrackerBOS != undefined) {
          for (const key in response.data.response.vehicleTrackerBOS) {
            if (response.data.response.vehicleTrackerBOS.hasOwnProperty(key)) {
              const element = response.data.response.vehicleTrackerBOS[key];
              let tele = {
                "type": "profile",
                "subtype": "vehicle",
                "id": element.assetId,
                "tripId": element.tripId,
                "onTrip": true,
                "org": {
                  "id": partnerId
                },
                "regNo": element.assetBO.vehicleNumber,
                "driverName": element.assetBO.driverName,
                "contactNumber": element.assetBO.contactNumber,
                "telematicData": {
                  "imei": element.imei ? element.imei : 'N/A',
                  "brand": "N/A",
                  "model": element.assetBO.vehicleType,
                  "variant": element.assetBO.capacity,
                  "gps": {
                    "location": {
                      "lat": element.heliosReponse.latitude,
                      "long": element.heliosReponse.longitude
                    },
                    "speed": 0.0,
                    "angle": 90
                  },
                  "ignition": element.heliosReponse.ignition ? 1 : 0,
                  "movement": 0.0,
                  "ignitionStateChangeTime": element.heliosReponse.lastKnownTime,
                  "staticStateChangeTime": element.heliosReponse.lastKnownTime,
                  "unixTimeStamp": element.heliosReponse.lastKnownTime
                },
                "assetBO": element.assetBO,
                "heliosReponse": element.heliosReponse,
                "simTracker": element.heliosReponse.simTracker
              }
              teleTrackingData[element.assetId] = tele;

            }
          }
        }
        payloadData = {
          res: teleTrackingData,
          tripStatus: "ONTRIP"
        }
        dispatch(vehicleOnTripSimTrackingData(payloadData));
      });
    } else {
      dispatch(vehicleSimTrackingAllVehicleDataBegin(null));
      return trackingService.getTelematicTrackingValues().then((response) => {
        let teleTrackingData = {};
        let payloadData = {};
        if (response != undefined && response.data != undefined && response.data.response != undefined && response.data.response.response != undefined) {
          //dispatch(vehicleTelematicTrackingData(response.data));
          teleTrackingData = response.data.response.response;
        }
        trackingService.getSimTrackingValues().then((response) => {
          if (response != undefined && response.data != undefined && response.data.response != undefined && response.data.response.response != undefined) {
            if (!isEmpty(response.data.response.response.clientResultSet)) {
              for (const key in response.data.response.response.clientResultSet) {
                if (response.data.response.response.clientResultSet.hasOwnProperty(key)) {
                  const element = response.data.response.response.clientResultSet[key];
                  let data = {
                    "id": element.id,
                    "contractId": element.id,
                    "type": element.type,
                    "subtype": element.subtype,
                    "vendor": element.vendor,
                    "statusChangeDate": element.statusChangeDate,
                    "sim": true
                  }
                  let igni = 0;
                  if (!isEmpty(element.vehicle)) {
                    if (!isEmpty(element.vehicle.regNo)) {
                      data.regNo = element.vehicle.regNo;
                    }
                    if (!isEmpty(element.vehicle.id)) {
                      data.id = element.vehicle.id;
                    }
                    if (!isEmpty(element.vehicle.telematicData)) {
                      data.telematicData = element.vehicle.telematicData;
                      if (!isEmpty(element.frequency)) {
                        if (!isEmpty(element.status) && element.status == 'location_polling' && !isEmpty(element.frequency) && Number(element.frequency) == 0) {
                          data.telematicData.ignition = 0;
                        } else if (!isEmpty(element.status) && element.status == 'location_polling' && !isEmpty(element.frequency) && Number(element.frequency) > 0) {
                          data.telematicData.ignition = 1
                        }
                      }
                      /*if(isEmpty(element.vehicle.telematicData.unixTimeStamp) && !isEmpty(element.statusChangeDate)){
                        data.telematicData.unixTimeStamp = Number(new Date(element.statusChangeDate).getTime());
                      }*/
                    }
                  }
                  teleTrackingData[element.id] = data;
                }
              }
            }
            payloadData = {
              res: teleTrackingData,
              tripStatus: "ALL"
            }
            dispatch(vehicleSimTrackingData(payloadData));
          }
        })
      })
    }
  };
}
export function getBeeconSimTrackingData() {
  return function (dispatch) {
    return trackingService.getSimTrackingValues().then((response) => {
      let payloadData = {};
      if (response != undefined && response.data != undefined) {
        payloadData = {
          res: response.data,
          tripStatus: "ALL"
        }
        dispatch(vehicleSimTrackingData(payloadData));
      }
    })
  };
}
export function onSearchTrackingDataAction(params) {
  return function (dispatch) {
    dispatch(getHeliosDataLoading(true))
    let payloadData = {};
    payloadData.vehicleTrackerBOS = []
    dispatch(heliosFilteredData(payloadData));
    return trackingService.getSearchedTrackingData(params.partnerId, params.data).then((response) => {
      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {

        let trackingResponse = response.data.response
        if (trackingResponse.vehicleTrackerBOS) {
          payloadData.vehicleTrackerBOS = trackingResponse.vehicleTrackerBOS;
        }
      }
      dispatch(heliosFilteredData(payloadData));
      dispatch(getHeliosDataLoading(false))
    });
  }
}

export function onSearchByVehicle(data) {
  return function (dispatch) {
    dispatch(getHeliosDataLoading(true))
    let payloadData = {};
    payloadData.vehicleTrackerBOS = []
    dispatch(heliosFilteredData(payloadData));
    return trackingService.getSearchByVehcile(data).then((response) => {
      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {

        let trackingResponse = response.data.response;

        if (trackingResponse.assetTrackingDetails) {
          payloadData.assetTrackingDetails = trackingResponse.assetTrackingDetails;
        }
      }
      dispatch(heliosFilteredData(payloadData));
      dispatch(getHeliosDataLoading(false))
    });
  }
}
export function getHeliosVehicleHistoryData(params) {
  let payloadData = {};
  payloadData.heliosVehicleHistoryData = {}
  return function (dispatch) {
    dispatch({
      type: CONSTANTS.HELIOS_TRACKING_BEGIN,
      payload: {}
    })
    dispatch(heliosVehicleHistoryData(payloadData));
    return trackingService.getAtlasHeliosTrackingHistory(params.filters).then((response) => {
      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {
        let trackingResponse = response.data.response
        payloadData.heliosVehicleHistoryData = trackingResponse.assetTrackingHistory;
      }
      dispatch(heliosVehicleHistoryData(payloadData));
      dispatch({
        type: CONSTANTS.HELIOS_TRACKING_END,
        payload: {}
      })
    }, () => {
      dispatch(heliosVehicleHistoryData(payloadData));
      dispatch({
        type: CONSTANTS.HELIOS_TRACKING_END,
        payload: {}
      })
    });
  }
}
export function getHeliosVehicleHistoryTripData(tripId) {
  let payloadData = {};
  payloadData.heliosVehicleHistoryData = {}
  return function (dispatch) {
    dispatch({
      type: CONSTANTS.HELIOS_TRACKING_BEGIN,
      payload: {}
    })
    dispatch(heliosVehicleHistoryData(payloadData));
    return trackingService.getSearchByTrip(tripId).then((response) => {
      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {
        let trackingResponse = response.data.response
        payloadData.heliosVehicleHistoryData = trackingResponse.assetTrackingHistory;
      }
      dispatch(heliosVehicleHistoryData(payloadData));
      dispatch({
        type: CONSTANTS.HELIOS_TRACKING_END,
        payload: {}
      })
    }, () => {
      dispatch(heliosVehicleHistoryData(payloadData));
      dispatch({
        type: CONSTANTS.HELIOS_TRACKING_END,
        payload: {}
      })
    });
  }
}
export function getHeliosListDataAction(params) {
  return function (dispatch) {
    return trackingService.getDistanceLocationData(params.partnerId, params.range).then((response) => {
      let payloadData = {};
      payloadData.heliosDistanceListData = {};
      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {
        let heliosDistanceListData = response.data.response
        //let heliosDistanceListData = heliosDistanceListDataExample.response;
        if (heliosDistanceListData.vehicleDistanceReport) {
          let lists = [];
          let key = 1;
          for (const vehiclekey in heliosDistanceListData.vehicleDistanceReport) {
            if (heliosDistanceListData.vehicleDistanceReport.hasOwnProperty(vehiclekey)) {
              const vehicleElement = heliosDistanceListData.vehicleDistanceReport[vehiclekey];
              let driverName = vehicleElement.driverName ? vehicleElement.driverName : 'N/A';
              let vendorName = vehicleElement.vendorName ? vehicleElement.vendorName : 'N/A';
              if (vehicleElement.distanceReport) {
                for (const timeKey in vehicleElement.distanceReport) {
                  if (vehicleElement.distanceReport.hasOwnProperty(timeKey)) {
                    const timeElement = vehicleElement.distanceReport[timeKey];
                    let value = timeElement;
                    value.driverName = driverName;
                    value.vendorName = vendorName;
                    value.dateTime = timeKey;
                    value.key = key++;
                    lists.push(value);
                  }
                }
              }
            }
          }
          payloadData.heliosDistanceListData = lists;
        }
      }
      dispatch(heliosDistanceListData(payloadData))
    });
  }
}
export function getHeliosCustomerBasedOnLocationAction(params) {
  return function (dispatch) {
    return trackingService.getPartnerCustomersMappedLocationData(params.partnerId, params.partners, params.locations).then((response) => {
      let payloadData = {};
      payloadData.customers = []
      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {
        let customerResponse = response.data.response
        if (customerResponse.customers) {
          payloadData.customers = customerResponse.customers;
        }
      }
      dispatch(heliosLoadCustomersBasedOnLocation(payloadData))
    });
  }
}
export function getHeliosFilteredDataAction(params) {
  return function (dispatch) {
    dispatch(getHeliosDataLoading(true))
    let payloadData = {
      vehicleTrackerBOS: []
    };
    //payloadData.vehicleTrackerBOS=null;
    dispatch(heliosFilteredData(payloadData));
    trackingService.getFilteredHeliosTrackingData(params.partnerId, params.filters).then((response) => {
      payloadData.vehicleTrackerBOS = []
      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {
        let trackingResponse = response.data.response
        if (trackingResponse.vehicleTrackerBOS) {
          payloadData.vehicleTrackerBOS = trackingResponse.vehicleTrackerBOS;
        }
      }
      dispatch(heliosFilteredData(payloadData));
      dispatch(getHeliosDataLoading(false))
    });
  }
}
export function getHeliosInitialDataAction(request) {
  return function (dispatch) {
    dispatch(getHeliosDataLoading(true));
    return trackingService.getPartnerMappedLocationData(request.partnerId).then((response) => {
      let payloadData = {};
      payloadData.locations = []
      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {
        let locationResponse = response.data.response
        if (locationResponse.locations) {
          payloadData.locations = locationResponse.locations;
        }
      }
      trackingService.getPartnerCustomersMappedLocationData(request.partnerId, [Number(request.partnerId)], [Number(request.locationId)]).then((response) => {
        payloadData.customers = []
        if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {
          let customerResponse = response.data.response
          if (customerResponse.customers) {
            payloadData.customers = customerResponse.customers;
          }
        }
        trackingService.getFilteredHeliosTrackingData(request.partnerId, request.filters).then((response) => {
          payloadData.vehicleTrackerBOS = []
          if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200' && response.data.response) {
            let trackingResponse = response.data.response
            if (trackingResponse.vehicleTrackerBOS) {
              payloadData.vehicleTrackerBOS = trackingResponse.vehicleTrackerBOS;
            }
            dispatch(heliosInitialData(payloadData));
            dispatch(getHeliosDataLoading(false))
          }
        });
      });
    })
  };
}

function vehicleTelematicTrackingData(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_TELEMATIC_DETAILS_SUCCESS,
    payload: res
  }
}
function vehicleSimTrackingData(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_SIM_DETAILS_SUCCESS,
    payload: res
  }
}
function heliosInitialData(res) {
  return {
    type: CONSTANTS.GET_HELIOS_INITIAL_DATA,
    payload: res
  }
}
function heliosFilteredData(res) {
  return {
    type: CONSTANTS.GET_HELIOS_FILTERED_DATA,
    payload: res
  }
}
export function getHeliosEmptyData(params) {
  return {
    type: CONSTANTS.GET_HELIOS_EMPTY_DATA,
    payload: []
  }
}
export function getHeliosDataLoading(value) {
  return {
    type: CONSTANTS.GET_HELIOS_DATA_LOADING,
    payload: value
  }
}
function heliosLoadCustomersBasedOnLocation(res) {
  return {
    type: CONSTANTS.GET_HELIOS_CUSTOMERS_DATA,
    payload: res
  }
}
function heliosDistanceListData(res) {
  return {
    type: CONSTANTS.GET_HELIOS_DISTANCE_DATA,
    payload: res
  }
}
function heliosVehicleHistoryData(res) {
  return {
    type: CONSTANTS.GET_HELIOS_VEHICLE_HISTORY_DATA,
    payload: res
  }
}
function vehicleOnTripSimTrackingData(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_ONTRIP_SIM_DETAILS_SUCCESS,
    payload: res
  }
}

function vehicleSimTrackingAllVehicleDataBegin(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_SIM_DETAILS_BEGIN,
    payload: res
  }
}
function vehicleSimTrackingOnTripDataBegin(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_ONTRIP_SIM_DETAILS_BEGIN,
    payload: res
  }
}

function vehicleTelematicTrackingSocketData(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_TELEMATIC_DETAILS_SOCKET,
    payload: res
  }
}


export function getVehicleHistoricalTrackingData(vehicleId, orgId, startTime, endTime, simTracking, contractId, simOnTripTrack, OnTripTrack) {
  return function (dispatch) {
    dispatch(vehicleHistoricalTrackingData(null));
    if (!isEmpty(simOnTripTrack) && simOnTripTrack && !isEmpty(contractId)) {
      return trackingService.getTripTrackingData(contractId).then((response) => {
        if (response != undefined && response.data != undefined && response.data.response != undefined) {
          response.data.response.onTrip = true;
          dispatch(vehicleHistoricalTrackingData(response.data.response));
        } else {
          dispatch(vehicleHistoricalTrackingData({}));
        }
      })
    } else if (!isEmpty(OnTripTrack) && OnTripTrack && !isEmpty(contractId)) {
      return trackingService.getTripTrackingData(contractId).then((response) => {
        if (response != undefined && response.data != undefined && response.data.response != undefined) {
          response.data.response.onTrip = true;
          dispatch(vehicleHistoricalTrackingData(response.data.response));
        } else {
          dispatch(vehicleHistoricalTrackingData({}));
        }
      })
    } else if (!isEmpty(simTracking) && simTracking && !isEmpty(contractId)) {
      return trackingService.getVehicleSimHistoricTrackingData(vehicleId, orgId, startTime, endTime, contractId).then((response) => {
        if (response != undefined && response.data != undefined && response.data.response != undefined && response.data.response.response != undefined) {
          response.data.response.onTrip = false;
          dispatch(vehicleHistoricalTrackingData(response.data.response));
        } else {
          dispatch(vehicleHistoricalTrackingData({}));
        }
      })
    } else {
      return trackingService.getVehicleHistoricTrackingData(vehicleId, orgId, startTime, endTime).then((response) => {
        if (response != undefined && response.data != undefined && response.data.response != undefined && response.data.response.response != undefined) {
          response.data.response.onTrip = false;
          dispatch(vehicleHistoricalTrackingData(response.data.response));
        } else {
          dispatch(vehicleHistoricalTrackingData({}));
        }
      })
    }
  };
}

function vehicleHistoricalTrackingData(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_HISTORIC_DETAILS_SUCCESS,
    payload: res
  }
}
function vehicleHistoricalSimTrackingData(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_SIM_HISTORIC_DETAILS_SUCCESS,
    payload: res
  }
}


function vehicleTrackingData(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_DETAILS_SUCCESS,
    payload: res
  }
}

export function getRoutDate(data) {
  return function (dispatch) {
    return trackingService.getRouteValues(data).then((response) => {
      dispatch(routeTrackingData(response.data.response));
    })
  };
}

function routeTrackingData(res) {
  return {
    type: CONSTANTS.GET_ROUTE_TRSCKING_DETAILS_SUCCESS,
    payload: res
  }
}

function vehicleTrackingTypeData(res) {
  return {
    type: CONSTANTS.GET_VEHICLE_TRACKING_TYPE,
    payload: res
  }
}

