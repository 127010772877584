import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ScHubVisibilityOverviewDashboard = () => {

    return (
        <>
            <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

                <div className="font-size-l-3 grey-text">Mid Mile</div>

                <div className="flex-column flex-gap-xl">
                    <MetabaseWidget text={"Shortage Report"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 388, reportName: "shortage-report",
                            lockedParams: ["partner_id"], height: "400px"
                        }} />

                    <MetabaseWidget text={"Overage Report"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 389, reportName: "overage-report",
                            lockedParams: ["partner_id"], height: "400px"
                        }} />

                    <MetabaseWidget text={"Trip Challan Details - Trip ID Level"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 479, reportName: "trip-challan-details-trip-id-level",
                            height: "400px"
                        }} />

                    <MetabaseWidget text={"Manifest Details"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 590, reportName: "manifest-details",
                            lockedParams: ["partner_id"], height: "400px"
                        }} />
                </div>


            </div>
        </>
    )

}

export default ScHubVisibilityOverviewDashboard;