import { Spin } from "antd";
import { useEffect, useState } from "react";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { Constants } from "../../../common/utils/constants";
import { useSelector } from "react-redux";

const monitoringReportsService = new MonitoringReportsService();

export default function MetabaseQuestion({ reportName, dashboardNo, questionNo,
    autoRefresh, autoRefreshTimetout, lockedParams,
    customParams, height, wrapperWidth, dateParams, onClickFuncAfterLoad }) {

    const [embedUrl, SetEmbedUrl] = useState(false);
    const userLocationMapping = useSelector(state => state.userLocationMappingReducer.userLocationMapping);

    useEffect(() => {
        SetEmbedUrl(false);
        var params = customParams ? { ...customParams } : {};
        if (lockedParams && lockedParams.indexOf("location_id") > -1) {
            params.location_id = Constants.userLocationId || Constants.LOGGED_PARTNER_LOCATION_ID;
        }
        if (lockedParams && lockedParams.indexOf("partner_id") > -1) {
            params.partner_id = Constants.CURRENT_USER_ID || Constants.PARTNER_ID;
        }
        if (lockedParams && lockedParams.indexOf("location_alias") > -1) {
            const user = JSON.parse(atob(localStorage?.user));
            params.location_alias = localStorage?.currentLocationName ?? user?.location?.name;
        }
        if (lockedParams && lockedParams.indexOf("user_id") > -1) {
            params.user_id = Constants.CURRENT_USER_ID;
        }
        if (lockedParams && lockedParams.indexOf("cluster_manager") > -1) {
            if (userLocationMapping && Object.keys(userLocationMapping)?.length == 1) {
                params.cluster_manager = Object.keys(userLocationMapping)[0].split("__").join(" ")
            }
            else if (userLocationMapping && Object.keys(userLocationMapping)?.length > 1) {
                params.cluster_manager = userLocationMapping[Object.keys(userLocationMapping)[0]]?.[0]?.opsManager;
            }
        }
        if (lockedParams && lockedParams.indexOf("area_manager") > -1) {
            if (userLocationMapping && Object.keys(userLocationMapping)?.length == 1) {
                params.area_manager = Object.keys(userLocationMapping)[0].split("__").join(" ")
            }
            else if (userLocationMapping && Object.keys(userLocationMapping)?.length > 1) {
                params.area_manager = null;
            }
        }
        monitoringReportsService.getEmbeddedURLByPayload({
            reportName: reportName,
            embedUrlGeneric: {
                resource: { question: questionNo, dashboard: dashboardNo },
                params: { ...params }
            }
        })
            .then(response => {

                let newUrl = response?.data?.response?.embedUrl

                if (newUrl && newUrl.length > 0) {

                    const formattedDate = new Date().toISOString().split('T')[0]
                    const [baseUrl, hashStringFormat] = response?.data?.response?.embedUrl.split('#')

                    let urlParams = ""
                    if (dateParams != null) {
                        if (dateParams.has("startDate")) { urlParams += `&start_date=${formattedDate}` }
                        if (dateParams.has("endDate")) { urlParams += `&end_date=${formattedDate}` }
                    }
                    if (autoRefresh) {
                        urlParams += `&refresh=${autoRefreshTimetout || 60}`
                    }

                    newUrl = `${baseUrl}${urlParams != null ? '?' + urlParams : urlParams}${hashStringFormat ? "#" + hashStringFormat : ""}`
                }

                SetEmbedUrl(newUrl);
            })
    }, [reportName])

    return (
        <div className="white-bg position-relative" style={{ width: wrapperWidth }}>
            {
                embedUrl ?
                    <>
                        <iframe src={embedUrl} frameBorder={0} width="100%"
                            style={{ height: height || "calc(100vh - 150px)" }}
                        />
                        {onClickFuncAfterLoad &&
                            <div
                                onClick={() => onClickFuncAfterLoad()}
                                className={"metabase-widget-v1"}
                            >
                            </div>
                        }
                    </> :
                    <Spin spinning={true}></Spin>
            }
        </div>
    )
}