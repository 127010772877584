import { Button, Icon, Modal, Upload } from "antd";
import AWS from "aws-sdk";
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import * as Constants from "./../../../common/utils/constants";

const { Dragger } = Upload;

const FileUpload = props => {
  const [fileList, updateFileList] = useState([]);
  const [uploading, updateUploading] = useState(false);
  const [isUploadDisabled, updateIsUploadDisabled] = useState(false);
  const [uploadButtonText, setUploadButtontext] = useState('Start Upload');
  const [acceptTypes, updateAcceptTypes] = useState(getInitialAceeptTypes());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let types = getInitialAceeptTypes()
    updateAcceptTypes(types);
    filePropObj['accept'] = types
  }, [props.acceptTypes]);

  useEffect(() => {
    if (props.clearFileAfterUpload) {
      handleClear()
    }
  }, [props.clearFileAfterUpload])


  function getFileExtension(fileName) {
    return "." + fileName.split(".").pop();
  }

  function getInitialAceeptTypes() {
    if (props.acceptTypes && Array.isArray(props.acceptTypes)) return props.acceptTypes
    else return []
  }

  function checkAcceptTypes(file) {
    if (acceptTypes.length) {
      const extension = getFileExtension(file.name);
      const isValidType = acceptTypes.indexOf(extension) > -1;
      if (!isValidType) {
        return false;
      }
      return isValidType;
    }
    return true;
  }

  function handleClear() {
    updateFileList([]);
    updateIsUploadDisabled(false);
    if (typeof props.handleClearCallBack === "function") {
      props.handleClearCallBack();
    }
    setUploadButtontext('Start Upload');
  }

  function handleUpload(newFile) {
    if (props.showUploadBeginMessage) {
      notifyApiSuccess(props.uploadBeginMessage, "Uploading");
    }
    updateUploading(true);
    setUploadButtontext('Uploading');
    updateIsUploadDisabled(true)
    let file = fileList[0];
    if (newFile && !newFile.target) {
      file = newFile;
    }

    if (file.size >= 10000000) {
      setLoading(false);
      return Modal.error({
        title: "File size is greater than 10MB! Kindly, choose a smaller file and try again."
      });
    }

    if (props?.showLoader) {
      setLoading(true);
    }

    if (file && checkAcceptTypes(file)) {
      if (!props.directUpload && !props.disableUploadButton) {
        props.onUploadFinish(file);
        if (props.clearFileAfterUpload) { handleClear() }
        updateUploading(false);
        updateIsUploadDisabled(true)
        setUploadButtontext('Retry Uploading');
      } else {
        if (props?.fileSizeLimit) {
          const isLt5M = file.size / 1024 / 1024 > props?.fileSizeLimit;

          if (isLt5M) {
            updateUploading(false);
            setLoading(false);
            setUploadButtontext('Uploaded');
            updateIsUploadDisabled(false)
            notifyApiError(`File size is greater than ${props?.fileSizeLimit} MB`);
          } else {
            AwsUploadLink(file)
          }
        } else {
          AwsUploadLink(file)
        }

      }
    } else {
      // onError
      updateUploading(false);
      setLoading(false);
      setUploadButtontext('Uploading Failed');
      updateIsUploadDisabled(false)
      notifyApiError(props.incorrectFileType ? props.incorrectFileType : "Something is wrong. Please check file or type.");
      console.error("Something went wrong.");
    }
  }

  function AwsUploadLink(file) {
    AWS.config.region = Constants.Constants.s3Connection[props.objKey === 'reports' ? 'mumbai' : 'default'].region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: Constants.Constants.s3Connection[props.objKey === 'reports' ? 'mumbai' : 'default'].pool
    });

    const bucketName = Constants.Constants.s3Connection[props.objKey === 'reports' ? 'mumbai' : 'default'].bucketName;
    const bucket = new AWS.S3({
      params: {
        Bucket: bucketName
      }
    });
    const uuid = Math.random();
    const fileExtension = getFileExtension(file.name);
    let key;
    if (props.path && props.path.length) {
      key = `${Constants.Constants.ENV}/${Constants.Constants.PARTNER_ID}/${props.path
        }/${props.objKey}_${uuid}.${fileExtension}`;
    } else {
      key = `${Constants.Constants.ENV}/${Constants.Constants.PARTNER_ID}/${props.objKey
        }_${uuid}.${fileExtension}`;
    }

    const filePath =
      bucket.endpoint.protocol +
      "//" +
      bucketName +
      "." +
      bucket.endpoint.hostname +
      "/" +
      key;
    const params = {
      Key: key,
      ContentType: file.type,
      Body: file,
      ACL: Constants.Constants.s3Connection[props.objKey === 'reports' ? 'mumbai' : 'default'].acl,
      Bucket: bucketName
    };
    bucket.putObject(params).send((err, data) => {
      return new Promise((resolve, reject) => {
        if (err) {
          setLoading(false);
          notifyApiError(JSON.stringify(err), "File Upload");
          console.error("ERROR: " + err);
          updateUploading(false);
          updateIsUploadDisabled(false)
          setUploadButtontext('Retry Upload');
          reject(false);
        } else {
          // onSuccess(data.response, file);

          if (_.hasIn(props, 'showToastMessage') && !props?.showToastMessage) {
            console.info("file upload successfully");
          } else {
            notifyApiSuccess("Successfully Uploaded.", "File Upload");
          }
          setLoading(false);
          updateUploading(false);
          setUploadButtontext('Uploaded');
          updateIsUploadDisabled(true)
          props.onUploadFinish(filePath, file.name);
          if (props.clearFileAfterUpload) {
            handleClear()
          }
          resolve(true);
        }
      });
    });
  }

  function getDragDropHtml() {

    return (
      <>
        <Dragger {...filePropObj}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p style={{ marginRight: '10px' }}> (Acceptabe file(s): {acceptTypes ? acceptTypes.join(', ') : 'Any File'} ) </p>
        </Dragger>
        {
          !props.disableUploadButton
            ?
            <>
              <Button
                onClick={() => handleUpload('')}
                disabled={fileList.length === 0 || isUploadDisabled}
                loading={uploading}
                style={{ marginTop: 16 }}
              >
                {uploadButtonText}
              </Button>
              <Button
                type="danger"
                onClick={handleClear}
                disabled={fileList.length === 0}
                style={{ marginTop: 16, marginLeft: 5 }}
              >
                Clear
              </Button>
            </>
            : ''
        }
      </>
    );
  }

  function onRemove(file) {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    updateFileList(newFileList);
    updateUploading(false);
    setUploadButtontext('Start Upload');
    if (typeof props.handleRemove === "function") {
      props.handleRemove(file, index);
    }
  }

  function getButtonHtml() {
    let buttonHtml = (<Button disabled={props.disableAll} icon="upload"> Click to Upload </Button>)
    if (props.uploadButtonHtml) {
      buttonHtml = props.uploadButtonHtml;
    }
    if (props.showUploadList === false && fileList.length === 1) {
      buttonHtml = (
        <Button style={{ padding: "5px 20px", height: "auto", maxWidth: 250 }}>
          <div className="flex-box flex-gap-l justify-content-between align-items-center">
            <div className="flex-box flex-gap-m align-items-center" style={{ maxWidth: "90%" }}>
              <Icon type="file-excel" />
              <div className="text-ellipsis" style={{ maxWidth: "90%" }}> {fileList[0]?.name} </div>
            </div>
            <Icon onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              onRemove(fileList[0]);
            }} type="close" />
          </div>
        </Button>
      )
    }
    let buttonHtmlWithSpinner =
      props?.showLoader ?
        props.doNotAllowMultiple && loading ?
          <Button className="text-ellipsis text-ellipsis-btn" loading={true}
            style={{ padding: "5px 20px", height: "auto", maxWidth: 250 }}>
            {fileList[0]?.name || "Uploading file"}
          </Button> :
          buttonHtml
        : buttonHtml;

    return <Upload {...filePropObj} showUploadList={props.showUploadList} className={props.className} style={props.style}>{buttonHtmlWithSpinner}</Upload>;
  }

  function handleOnChange(event) {
    if (typeof props.beforeUpload === 'function') {
      props.beforeUpload(event.file);
    }
    if (!props.doNotAllowMultiple) {
      updateFileList([...fileList, event.file]);
    } else {
      updateFileList([event.file]);
    }
    handleUpload(event.file);
  }

  const filePropObj = {
    accept: acceptTypes.join(","),
    disabled: !props.disableUploadButton ? fileList.length > 0 : false,
    multiple: props.multiple,
    beforeUpload: !props?.disableUploadButton
      ? file => {
        if (!props.doNotAllowMultiple) {
          updateFileList([...fileList, file]);
        } else {
          updateFileList([file]);
        }
        return false;
      } : null,
    onRemove: onRemove,
    fileList,
    customRequest: (e) => { return props.disableUploadButton ? handleOnChange(e) : null }
  };

  switch (props.fileUploadType) {
    case Constants.UPLOAD_BUTTON_TYPE.DRAGDROP:
      return getDragDropHtml();
    case Constants.UPLOAD_BUTTON_TYPE.BUTTON:
      return getButtonHtml();
    default:
      return getButtonHtml();
  }
};
export default FileUpload;
