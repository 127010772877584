import { useEffect, useState } from "react";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { Constants } from "../../../common/utils/constants";
import { Radio } from "antd";
import RadioGroup from "antd/lib/radio/group";
import PickupReportMeta from "../../metabase-dashboards/first-mile/pickup-report";

export default function PickupReport() {

    const [embedUrl, SetEmbedUrl] = useState(false);
    const [tabKey, SetTabKey] = useState("location_user");

    const user = localStorage.user ? JSON.parse(atob(localStorage.user)) : {};

    useEffect(() => {
        if (user.partner?.id == 268) {
            (new MonitoringReportsService()).getEmbeddedURLByPayload({
                reportName: "pickup-report-admin",
                embedUrlGeneric: {
                    resource: { dashboard: 163 },
                    params: {}
                }
            })
                .then(response => {
                    SetEmbedUrl(response?.data?.response?.embedUrl);
                })
        }
    }, []);

    const onTabChange = key => {
        SetTabKey(key);
        SetEmbedUrl(false);
        if (key == "location_user") {
            (new MonitoringReportsService()).getEmbeddedURLByPayload({
                reportName: "pickup-report-admin",
                embedUrlGeneric: {
                    resource: { dashboard: 163 },
                    params: {}
                }
            })
                .then(response => {
                    SetEmbedUrl(response?.data?.response?.embedUrl);
                })
        }
        else if (key == "shipment") {
            (new MonitoringReportsService()).getEmbeddedURLByPayload({
                reportName: "pickup-report",
                embedUrlGeneric: {
                    resource: { question: 1749 },
                    params: {}
                }
            })
                .then(response => {
                    SetEmbedUrl(response?.data?.response?.embedUrl);
                })
        }
    }

    return (
        <>
            {user.partner?.id == 268 ?
                <div className="white-bg" style={{ height: "calc(100vh - 150px)", marginBottom: 25 }}>
                    {
                        user.partner?.id == 268 &&
                        <div className="spacer-m flex-box flex-gap-l align-items-center">
                            <RadioGroup buttonStyle="solid" onChange={event => onTabChange(event?.target?.value)} value={tabKey} >
                                <Radio.Button className="report-preview-radio" value={"location_user"}> Location & User Level </Radio.Button>
                                <Radio.Button className="report-preview-radio" value={"shipment"}> Shipment Level </Radio.Button>
                            </RadioGroup>
                        </div>
                    }

                    <iframe height={"100%"} src={`${embedUrl}&refresh=600`} frameBorder={0} width="100%" />

                </div>
                :
                <PickupReportMeta />
            }
        </>
    )
}